import { createAction } from 'redux-actions'
import { Props } from 'types/core/props'
import { Template } from 'types/core/template'

export const GET_TEMPLATE_REQUEST = 'GET_TEMPLATE_REQUEST'
export const getTemplateRequest = createAction<Props>(GET_TEMPLATE_REQUEST)

export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS'
export const getTemplateSuccess = createAction<Template>(GET_TEMPLATE_SUCCESS)

export const GET_TEMPLATE_FAILURE = 'GET_TEMPLATE_FAILURE'
export const getTemplateFailure = createAction<void>(GET_TEMPLATE_FAILURE)
