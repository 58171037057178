import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css'

import { Routes } from 'routes'
import { store } from 'store'

import { Notification } from 'types/notifications'

import { socketNotifications } from 'requests/socket'

import { notificationsUpdateSuccess } from 'store/notifications/notifications.actions'

import Alert from 'components/molecules/Alert'
import GlobalErrorAlert from 'components/molecules/GlobalErrorAlert'
import GlobalPaymentStateAlert from 'components/molecules/GlobalPaymentStateAlert'

import './global-styles.css'

socketNotifications.on('notifications', (data: Notification[]) => {
  store.dispatch(notificationsUpdateSuccess(data))
})

const App = () => (
  <Provider store={store}>
    <div
      className="position-relative page"
      onDrop={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
      onDragOver={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <BrowserRouter>
        <GlobalErrorAlert />
        <GlobalPaymentStateAlert />
        <Routes />
      </BrowserRouter>
    </div>
    <Alert />
  </Provider>
)

export default App
