import { DictionaryEntry, DictionaryEntryType, DictionaryId } from 'types/dictionary'
import { ValuesHash } from 'lib/hash/values-hash'

export class DictionaryIdentityHash {
  public static get(entry: DictionaryEntry): DictionaryId {
    const identityValues = this.getIdentityValues(entry)
    const cleanedValues = this.clearIdentityValues(identityValues)
    return ValuesHash.hash(cleanedValues)
  }

  private static getIdentityValues(entry: DictionaryEntry): string[] {
    if (entry.type === DictionaryEntryType.PERSON) return [`${entry.type || ''}`, `${entry.name || ''}`, `${entry.birthday || ''}`]
    return [`${entry.type || ''}`, `${entry.phone || ''}`]
  }

  private static clearIdentityValues(values: string[]): string[] {
    return values.map((value) => `${value}`.toLowerCase().replace(/\s+/g, ' ').trim().replace(/ё/g, 'е'))
  }
}
