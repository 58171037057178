import { call, put, select } from 'redux-saga/effects'
import { fetchTakeOrder } from 'requests/order'
import { Action } from 'redux-actions'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'

import { fetchTakeOrderSuccess, fetchTakeOrderFailure } from '../orders.actions'

export function* fetchTakeOrderSaga(action: Action<string>) {
  try {
    const token = yield select(selectToken)
    yield call(fetchTakeOrder, action.payload, token)

    yield put(
      addAlert({
        text: `Заявка ${action.payload} успешно взята.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Взятие заявки',
      }),
    )
    yield put(fetchTakeOrderSuccess())
  } catch (error) {
    yield put(fetchTakeOrderFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Взятие заявки',
        defaultText: 'Не удалось взять заявку',
        textForCase: {
          403: 'Не удалось взять заявку.\n Её уже взяли.',
        },
      }),
    )
    console.error(`[fetchTakeOrderSaga]: ${error}`)
  }
}
