export enum AppSettings {
  STATISTIC_FEED = 'statisticFeed',
  STATISTIC_FOR_QUALITY_CONTROL = 'statisticForQualityControl',
  STATISTIC_ABOUT_EXPERTS = 'statisticAboutExperts',
  STATISTIC_GREEN = 'statisticGreen',
  ACTIVE = 'active',
}

export interface UserSettings {
  [AppSettings.STATISTIC_FEED]?: string
  [AppSettings.STATISTIC_ABOUT_EXPERTS]?: string
  [AppSettings.STATISTIC_FOR_QUALITY_CONTROL]?: string
  [AppSettings.STATISTIC_GREEN]?: string
  [AppSettings.ACTIVE]?: boolean
}

export interface User {
  role: UserRole
  name: string
  token?: string
  login: string
  id?: number
  features?: { [key in Feature]?: any }
  settings?: UserSettings
  debt?: boolean
}

export interface CreateUserData {
  role: UserRole
  name: string
  password: string
  login: string
  company?: string
}

export interface EditUserData {
  userId: string
  data: {
    role?: UserRole
    name?: string
    password?: string
    login?: string
    company?: string
  }
}

export interface DeleteUserData {
  // @TODO describe
}

export interface LoginData {
  login: string
  password: string
}

export interface RegisterData {
  // @TODO describe
}

export enum UserRole {
  ADMIN = 'admin',
  EXPERT = 'expert',
  CUSTOMER = 'customer',
  MANAGER = 'manager',
}

export enum Feature {
  ALLOW_PRIORITY = 'allowPriority',
  LOGISTIC_PAYER_BY_INN = 'logisticPayerByInn',
  HIDE_FOUND_PHONES = 'hide_found_phones',
  DOCUMENTS_AND_PHONES_CHECKING_IS_REQUIRED = 'documents_and_phones_checking_is_required',
  AUTOMATIC_RECHECKS = 'automatic_rechecks',
  USE_FELIX_FOR_API = 'use_felix_for_api',
  ALL_FIELDS_ARE_REQUIRED = 'all_fields_are_required',
  BILLING_URL = 'billingUrl',
  AVAILABLE_ORDER_TYPES = 'availableOrderTypes',
  CAN_SELECT_ORDER_TO_TAKE = 'canSelectOrderToTake',
  PAYMENT_STATE = 'paymentState',
  CLIENT_DUPLICATES = 'clientDuplicates',
  FASTIDIOUS_COMMON_DUPLICATES = 'fastidiousCommonDuplicates',
  DISABLE_COMMON_DUPLICATES = 'disableCommonDuplicates',
  OUTSOURCING_FASTIDIOUS_COMMON_DUPLICATES = 'outsourcingFastidiousCommonDuplicates',
  FASTIDIOUS_FELIX = 'fastidiousFelix',
  IMPORTANT_INSTRUCTIONS_FOR_l1 = 'importantInstructionsForL1',
  IMPORTANT_INSTRUCTIONS_FOR_E = 'importantInstructionsForE',
  COMPANY_ID = 'companyId',
  SUBROLE = 'subrole',
  RELATED_CUSTOMERS = 'relatedCustomers',
}
