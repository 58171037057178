import { Order } from 'types/order'

export const groupOrders = (orders: Order[], isFirstGroup: (order: Order) => boolean): [Array<Order>, Array<Order>] => {
  const result = orders.reduce(
    (obj: { firstGroup: Order[]; secondGroup: Order[] }, order: Order) =>
      isFirstGroup(order) ? { ...obj, firstGroup: [...obj.firstGroup, order] } : { ...obj, secondGroup: [...obj.secondGroup, order] },
    { firstGroup: [], secondGroup: [] },
  )

  return [result.firstGroup, result.secondGroup]
}
