import { put, select, call } from 'redux-saga/effects'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'

import { Customer } from 'types/customer'

import { getCustomers } from 'requests/customer'

import { getCustomersSuccess, getCustomersFailure } from 'store/admin/admin.actions'

export function* getCustomersSaga() {
  try {
    const token = yield select(selectToken)

    const response: { companies: Customer[] } = yield call(getCustomers, token)

    yield put(getCustomersSuccess(response.companies))
  } catch (error) {
    yield put(getCustomersFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение списка клиентов',
        defaultText: 'Не удалось получить клиентов.',
      }),
    )
    console.error(`[getCustomersSaga]: ${error}`)
  }
}
