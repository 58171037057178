import { handleActions } from 'redux-actions'
import { LoadingStatus } from 'types/loading'

import * as actions from './data-gateway.actions'

export interface DataGatewayState {
  loadingDataGatewayStatus: LoadingStatus
  loadingAttachPdfStatus: LoadingStatus
}

export const initialDataGatewayState: DataGatewayState = {
  loadingDataGatewayStatus: LoadingStatus.NONE,
  loadingAttachPdfStatus: LoadingStatus.NONE,
}

const fetchDataGatewayRequest = (state: DataGatewayState): DataGatewayState => ({
  ...state,
  loadingDataGatewayStatus: LoadingStatus.PENDING,
})

const fetchDataGatewaySuccess = (state: DataGatewayState): DataGatewayState => ({
  ...state,
  loadingDataGatewayStatus: LoadingStatus.SUCCESS,
})

const fetchDataGatewayFailure = (state: DataGatewayState): DataGatewayState => ({
  ...state,
  loadingDataGatewayStatus: LoadingStatus.FAILED,
})

const setInitialDataGatewayState = (): DataGatewayState => ({
  ...initialDataGatewayState,
})

export default handleActions<DataGatewayState, any>(
  {
    [actions.FETCH_DATA_GATEWAY_REQUEST]: fetchDataGatewayRequest,
    [actions.FETCH_DATA_GATEWAY_SUCCESS]: fetchDataGatewaySuccess,
    [actions.FETCH_DATA_GATEWAY_FAILURE]: fetchDataGatewayFailure,
    [actions.SET_INITIAL_DATA_GATEWAY_STATE]: setInitialDataGatewayState,
  },
  initialDataGatewayState,
)
