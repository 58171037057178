import { put, select, call } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'

import { UserSettings } from 'types/user'

import { updateUserSettings } from 'requests/user'

import { updateUserSettingsSuccess, updateUserSettingsFailure } from 'store/users/users.actions'

export function* updateUserSettingsSaga(action: Action<UserSettings>) {
  try {
    const token = yield select(selectToken)

    const settings: UserSettings = yield call(updateUserSettings, action.payload, token)

    yield put(updateUserSettingsSuccess(settings))
  } catch (error) {
    yield put(updateUserSettingsFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Изменение настроек пользователя',
        defaultText: 'Не удалось изменить настройки пользователя.',
      }),
    )
    console.error(`[updateUserSettingsSaga]: ${error}`)
  }
}
