import { Action, handleActions } from 'redux-actions'

import { LoadingStatus } from 'types/loading'
import { Props } from 'types/core/props'

import * as actions from './billing.actions'

export interface BillingState {
  settings: { [key: string]: { [key: string]: string } }
  currentSettings: { [key: string]: string }
  companyAccount: Props
  companyDetails: { [key: string]: string }
  getCurrentSettingsLoadingStatus: LoadingStatus
  getBillingSettingsLoadingStatus: LoadingStatus
  setBillingSettingsLoadingStatus: LoadingStatus
  getCompanyAccountLoadingsStatus: LoadingStatus
  updateCurrentCompanyDetailsLoadingStatus: LoadingStatus
  getCurrentCompanyDetailsLoadingStatus: LoadingStatus
  createCurrentCompanyDetailsLoadingStatus: LoadingStatus
}

export const initialBillingState: BillingState = {
  settings: {},
  currentSettings: {},
  companyAccount: {},
  companyDetails: {},
  getCurrentSettingsLoadingStatus: LoadingStatus.NONE,
  getBillingSettingsLoadingStatus: LoadingStatus.NONE,
  setBillingSettingsLoadingStatus: LoadingStatus.NONE,
  getCompanyAccountLoadingsStatus: LoadingStatus.NONE,
  updateCurrentCompanyDetailsLoadingStatus: LoadingStatus.NONE,
  getCurrentCompanyDetailsLoadingStatus: LoadingStatus.NONE,
  createCurrentCompanyDetailsLoadingStatus: LoadingStatus.NONE,
}

const getBillingSettingsRequest = (state: BillingState): BillingState => {
  return {
    ...state,
    getBillingSettingsLoadingStatus: LoadingStatus.PENDING,
  }
}

const getBillingSettingsSuccess = (state: BillingState, action: Action<any>): BillingState => {
  return {
    ...state,
    getBillingSettingsLoadingStatus: LoadingStatus.SUCCESS,
    settings: action.payload,
  }
}

const getBillingSettingsFailure = (state: BillingState): BillingState => {
  return {
    ...state,
    getBillingSettingsLoadingStatus: LoadingStatus.FAILED,
  }
}

const getCurrentBillingSettingsRequest = (state: BillingState): BillingState => {
  return {
    ...state,
    getCurrentSettingsLoadingStatus: LoadingStatus.PENDING,
  }
}

const getCurrentBillingSettingsSuccess = (state: BillingState, action: Action<any>): BillingState => {
  return {
    ...state,
    getCurrentSettingsLoadingStatus: LoadingStatus.SUCCESS,
    currentSettings: action.payload,
  }
}

const getCurrentBillingSettingsFailure = (state: BillingState): BillingState => {
  return {
    ...state,
    getCurrentSettingsLoadingStatus: LoadingStatus.FAILED,
  }
}

const setBillingSettingsRequest = (state: BillingState): BillingState => {
  return {
    ...state,
    setBillingSettingsLoadingStatus: LoadingStatus.PENDING,
  }
}

const setBillingSettingsSuccess = (state: BillingState): BillingState => {
  return {
    ...state,
    setBillingSettingsLoadingStatus: LoadingStatus.SUCCESS,
  }
}

const setBillingSettingsFailure = (state: BillingState): BillingState => {
  return {
    ...state,
    setBillingSettingsLoadingStatus: LoadingStatus.FAILED,
  }
}

const getCompanyAccountRequest = (state: BillingState): BillingState => {
  return {
    ...state,
    companyAccount: {},
    getCompanyAccountLoadingsStatus: LoadingStatus.PENDING,
  }
}

const getCompanyAccountSuccess = (state: BillingState, action: Action<any>): BillingState => {
  return {
    ...state,
    companyAccount: action.payload,
    getCompanyAccountLoadingsStatus: LoadingStatus.SUCCESS,
  }
}

const getCompanyAccountFailure = (state: BillingState): BillingState => {
  return {
    ...state,
    getCompanyAccountLoadingsStatus: LoadingStatus.FAILED,
  }
}

const getCurrentCompanyDetailsRequest = (state: BillingState): BillingState => {
  return {
    ...state,
    getCurrentCompanyDetailsLoadingStatus: LoadingStatus.PENDING,
  }
}

const getCurrentCompanyDetailsSuccess = (state: BillingState, action: Action<any>): BillingState => {
  return {
    ...state,
    getCurrentCompanyDetailsLoadingStatus: LoadingStatus.SUCCESS,
    companyDetails: action.payload,
  }
}

const getCurrentCompanyDetailsFailure = (state: BillingState): BillingState => {
  return {
    ...state,
    getCurrentCompanyDetailsLoadingStatus: LoadingStatus.FAILED,
  }
}

const updateCurrentCompanyDetailsRequest = (state: BillingState): BillingState => {
  return {
    ...state,
    updateCurrentCompanyDetailsLoadingStatus: LoadingStatus.PENDING,
  }
}

const updateCurrentCompanyDetailsSuccess = (state: BillingState, action: Action<Props>): BillingState => {
  return {
    ...state,
    companyDetails: { ...state.companyDetails, ...action.payload } as { [key: string]: string },
    updateCurrentCompanyDetailsLoadingStatus: LoadingStatus.SUCCESS,
  }
}

const updateCurrentCompanyDetailsFailure = (state: BillingState): BillingState => {
  return {
    ...state,
    updateCurrentCompanyDetailsLoadingStatus: LoadingStatus.FAILED,
  }
}

const createCurrentCompanyDetailsRequest = (state: BillingState): BillingState => {
  return {
    ...state,
    createCurrentCompanyDetailsLoadingStatus: LoadingStatus.PENDING,
  }
}

const createCurrentCompanyDetailsSuccess = (state: BillingState, action: Action<Props>): BillingState => {
  return {
    ...state,
    companyDetails: { ...action.payload } as { [key: string]: string },
    createCurrentCompanyDetailsLoadingStatus: LoadingStatus.SUCCESS,
  }
}

const createCurrentCompanyDetailsFailure = (state: BillingState): BillingState => {
  return {
    ...state,
    createCurrentCompanyDetailsLoadingStatus: LoadingStatus.FAILED,
  }
}

export default handleActions<BillingState, any>(
  {
    [actions.GET_BILLING_SETTINGS_REQUEST]: getBillingSettingsRequest,
    [actions.GET_BILLING_SETTINGS_SUCCESS]: getBillingSettingsSuccess,
    [actions.GET_BILLING_SETTINGS_FAILURE]: getBillingSettingsFailure,
    [actions.GET_CURRENT_BILLING_SETTINGS_REQUEST]: getCurrentBillingSettingsRequest,
    [actions.GET_CURRENT_BILLING_SETTINGS_SUCCESS]: getCurrentBillingSettingsSuccess,
    [actions.GET_CURRENT_BILLING_SETTINGS_FAILURE]: getCurrentBillingSettingsFailure,
    [actions.SET_BILLING_SETTINGS_REQUEST]: setBillingSettingsRequest,
    [actions.SET_BILLING_SETTINGS_SUCCESS]: setBillingSettingsSuccess,
    [actions.SET_BILLING_SETTINGS_FAILURE]: setBillingSettingsFailure,
    [actions.GET_COMPANY_ACCOUNT_REQUEST]: getCompanyAccountRequest,
    [actions.GET_COMPANY_ACCOUNT_SUCCESS]: getCompanyAccountSuccess,
    [actions.GET_COMPANY_ACCOUNT_FAILURE]: getCompanyAccountFailure,
    [actions.GET_CURRENT_COMPANY_DETAILS_REQUEST]: getCurrentCompanyDetailsRequest,
    [actions.GET_CURRENT_COMPANY_DETAILS_SUCCESS]: getCurrentCompanyDetailsSuccess,
    [actions.GET_CURRENT_BILLING_SETTINGS_FAILURE]: getCurrentCompanyDetailsFailure,
    [actions.UPDATE_CURRENT_COMPANY_DETAILS_REQUEST]: updateCurrentCompanyDetailsRequest,
    [actions.UPDATE_CURRENT_COMPANY_DETAILS_SUCCESS]: updateCurrentCompanyDetailsSuccess,
    [actions.UPDATE_CURRENT_COMPANY_DETAILS_FAILURE]: updateCurrentCompanyDetailsFailure,
    [actions.CREATE_CURRENT_COMPANY_DETAILS_REQUEST]: createCurrentCompanyDetailsRequest,
    [actions.CREATE_CURRENT_COMPANY_DETAILS_SUCCESS]: createCurrentCompanyDetailsSuccess,
    [actions.CREATE_CURRENT_COMPANY_DETAILS_FAILURE]: createCurrentCompanyDetailsFailure,
  },
  initialBillingState,
)
