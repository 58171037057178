import { AppState } from 'store/root.reducer'

export const selectBillingState = (state: AppState) => state?.billing

export const selectBillingSettings = (state: AppState) => selectBillingState(state)?.settings

export const selectCurrentBillingSettings = (state: AppState) => selectBillingState(state)?.currentSettings

export const selectBillingSettingsStatusOfGetCurrent = (state: AppState) => selectBillingState(state)?.getCurrentSettingsLoadingStatus

export const selectBillingSettingsStatusOfGet = (state: AppState) => selectBillingState(state)?.getBillingSettingsLoadingStatus

export const selectBillingSettingsStatusOfSet = (state: AppState) => selectBillingState(state)?.setBillingSettingsLoadingStatus

export const selectCompanyAccount = (state: AppState) => selectBillingState(state)?.companyAccount

export const selectGetCompanyAccountLoadingStatus = (state: AppState) => selectBillingState(state)?.getCompanyAccountLoadingsStatus

export const selectUpdateCurrentCompanyDetailsLoadingStatus = (state: AppState) => selectBillingState(state)?.updateCurrentCompanyDetailsLoadingStatus

export const selectCreateCurrentCompanyDetailsLoadingStatus = (state: AppState) => selectBillingState(state)?.createCurrentCompanyDetailsLoadingStatus

export const selectCurrentCompanyDetails = (state: AppState) => selectBillingState(state)?.companyDetails
