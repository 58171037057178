import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { DictionaryEntry, DictionaryEntryState, PersonDictionaryEntry } from 'types/dictionary'

import { selectDictionary } from 'store/dictionary/dictionary.selectors'
import { DictionaryIdentityHash } from 'store/dictionary/dictionary-identity-hash'

export const useDictionaryByEntry = (entry: DictionaryEntry): DictionaryEntryState => {
  const dictionary = useSelector(selectDictionary)

  return dictionary[DictionaryIdentityHash.get(entry)]
}

export const usePhoneDictionaryLinksByEntry = (sourceEntry: PersonDictionaryEntry, phones: string[]) => {
  const dictionary = useDictionaryByEntry(sourceEntry)
  const dictionaryEntryOfSourceEntry = useMemo(
    () => dictionary?.response?.entries?.find((entry) => DictionaryIdentityHash.get(entry) === DictionaryIdentityHash.get(sourceEntry)),
    [dictionary, sourceEntry],
  )

  return useMemo(
    () =>
      phones.map((phone) => {
        const phoneDictionaryEntry = dictionary?.response?.entries?.find((entry) => entry?.phone === phone)
        return dictionary?.response?.links?.find(
          (link) => link.entryIds.includes(phoneDictionaryEntry?.id) && link.entryIds.includes(dictionaryEntryOfSourceEntry?.id),
        )
      }),
    [dictionary, dictionaryEntryOfSourceEntry, phones],
  )
}
