import { put } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { AlertVariant } from 'types/alert'
import { AddHttpErrorData } from 'types/http-error'

import { addAlert } from 'store/alerts/alerts.actions'
import { fetchLogoutRequest } from 'store/users/users.actions'

export function* addHttpError(action: Action<AddHttpErrorData>) {
  try {
    const { error, header, defaultText, withoutDefaultAlert } = action.payload

    const textForCase = action.payload.textForCase || {}
    const withoutAlertCase = action.payload.withoutAlertCase || {}

    if ('isAxiosError' in error && error.isAxiosError) {
      switch (error.request.status) {
        case 401:
          if (!withoutAlertCase || !withoutAlertCase[401]) {
            yield put(
              addAlert({
                text: textForCase[401] || 'Ошибка авторизации!\nПопробуйте авторизоваться ещё раз.',
                variant: AlertVariant.DANGER,
                header,
                withLineBreaks: true,
              }),
            )
            if (!textForCase[401]) yield put(fetchLogoutRequest())
          }
          break

        case 403:
          if (!withoutAlertCase || !withoutAlertCase[403])
            yield put(
              addAlert({
                text: textForCase[403] || 'Ошибка доступа!\nНедостаточно прав.',
                lifetime: 10000,
                variant: AlertVariant.WARNING,
                header,
                withLineBreaks: true,
              }),
            )
          break

        case 404:
          if (!withoutAlertCase || !withoutAlertCase[404])
            yield put(
              addAlert({
                text: textForCase[404] || 'Данные не найдены.',
                lifetime: 10000,
                variant: AlertVariant.WARNING,
                header,
                withLineBreaks: true,
              }),
            )
          break

        case 500:
          if (!withoutAlertCase || !withoutAlertCase[500])
            yield put(
              addAlert({
                text: textForCase[500] || 'Ошибка сервера!\nОбратитесь в техподдержку.',
                lifetime: 10000,
                variant: AlertVariant.DANGER,
                header,
                withLineBreaks: true,
              }),
            )
          break

        case 0:
          if (!withoutAlertCase || !withoutAlertCase[0])
            yield put(
              addAlert({
                text: textForCase[0] || 'Проблемы с интернет соединением.\n Попробуйте ещё раз.',
                lifetime: 10000,
                variant: AlertVariant.DANGER,
                header,
                withLineBreaks: true,
              }),
            )
          break

        default:
          if (!withoutDefaultAlert)
            yield put(
              addAlert({
                text: `${defaultText}\nСтатус: ${error.request.status}`,
                lifetime: 5000,
                variant: AlertVariant.DANGER,
                header,
                withLineBreaks: true,
              }),
            )
          break
      }
    } else {
      yield put(
        addAlert({
          text: 'Что-то пошло не так!\nОбратитесь в техподдержку.',
          variant: AlertVariant.DANGER,
          header,
          withLineBreaks: true,
        }),
      )
    }
  } catch (error) {
    console.error(`[handleAddAxiosError]: ${error}`)
  }
}
