import { Order, OrderFields, PriorityId } from 'types/order'
import { groupOrders } from './group-orders'

export class ExpertSorter {
  static sort(orders: Order[]) {
    const descSortedOrders = [...orders].reverse()
    const [urgentOrders, regularOrders] = groupOrders(descSortedOrders, ExpertSorter.isUrgent)
    return [...urgentOrders, ...regularOrders]
  }

  static isUrgent(order: Order) {
    return String(order[OrderFields.PRIORITY]) === PriorityId.URGENTLY
  }
}
