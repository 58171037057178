import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  authPath,
  createOrderPath,
  searchOrderPath,
  ordersListPath,
  statisticPath,
  billingPath,
  expertsOnlinePath,
  l1OnlinePath,
  chatsPath,
} from 'routes/url-constants'
import { fetchLogoutRequest } from 'store/users/users.actions'
import { selectCurrentUser } from 'store/users/users.selectors'
import { getCompanyAccountRequest } from 'store/billing/billing.actions'

import { UserRole } from 'types/user'
import { NavLinkProps } from 'types/navLink'

type MenuProps = {
  isMenuOpen: boolean
  toggleMenu: () => void
  menuLinks: NavLinkProps[]
}

export const useMenu = (currentPath: string): MenuProps => {
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const toggleMenu = useCallback(() => setMenuOpen((prev) => !prev), [setMenuOpen])

  useEffect(() => {
    if (user?.role === UserRole.CUSTOMER) dispatch(getCompanyAccountRequest('current'))
  }, [user, dispatch])

  const createMenuItem = useCallback(
    (name: string, path: string, hidden: boolean = false) => ({
      name,
      path,
      onClick: () => {},
      isCurrent: currentPath === path,
      hidden,
    }),
    [currentPath],
  )

  let menuLinks: NavLinkProps[] = []

  if (!user)
    return {
      isMenuOpen,
      toggleMenu,
      menuLinks,
    }

  switch (user.role) {
    case UserRole.ADMIN:
      menuLinks = [
        createMenuItem('Панель администратора', '/admin/users'),
        createMenuItem('Создание заявки', createOrderPath),
        createMenuItem('Список заявок', ordersListPath),
        createMenuItem('Поиск заявок', searchOrderPath),
        createMenuItem('Чаты', chatsPath),
        { name: 'Выход', path: authPath, onClick: () => dispatch(fetchLogoutRequest()) },
      ]
      break

    case UserRole.CUSTOMER:
      menuLinks = [
        createMenuItem('Создание заявки', createOrderPath),
        createMenuItem('Список заявок', ordersListPath),
        createMenuItem('Поиск заявок', searchOrderPath),
        createMenuItem('Счета и оплаты', billingPath, user.features?.subrole !== 'customer-admin'),
        { name: 'Обучение', isExternalLink: true, path: 'https://stepik.org/220046' },
        { name: 'Выход', path: authPath, onClick: () => dispatch(fetchLogoutRequest()) },
      ]
      break

    case UserRole.EXPERT:
      menuLinks = [
        createMenuItem('Эксперты Online', expertsOnlinePath),
        createMenuItem('Создание заявки', createOrderPath),
        createMenuItem('Список заявок', ordersListPath),
        createMenuItem('Поиск заявок', searchOrderPath),
        createMenuItem('Статистика', statisticPath),
        createMenuItem('Чаты', chatsPath),
        { name: 'Выход', path: authPath, onClick: () => dispatch(fetchLogoutRequest()) },
      ]
      break

    case UserRole.MANAGER:
      menuLinks = [
        createMenuItem('Создание заявки', createOrderPath),
        createMenuItem('Список заявок', ordersListPath),
        createMenuItem('Поиск заявок', searchOrderPath),
        createMenuItem('Л1 онлайн', l1OnlinePath),
        createMenuItem('Статистика', statisticPath),
        createMenuItem('Чаты', chatsPath),
        { name: 'Выход', path: authPath, onClick: () => dispatch(fetchLogoutRequest()) },
      ]
      break

    default:
      menuLinks = [{ name: 'Выход', path: authPath, onClick: () => dispatch(fetchLogoutRequest()) }]
  }

  return {
    isMenuOpen,
    toggleMenu,
    menuLinks,
  }
}
