import { put, select, call } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { CreateCustomerData } from 'types/customer'

import { createCustomer } from 'requests/customer'

import { createCustomerSuccess, createCustomerFailure } from 'store/admin/admin.actions'

export function* createCustomerSaga(action: Action<CreateCustomerData>) {
  try {
    const token = yield select(selectToken)

    yield call(createCustomer, action.payload, token)

    yield put(createCustomerSuccess())
    yield put(
      addAlert({
        text: `Клиент успешно создан.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Создание клиента',
      }),
    )
  } catch (error) {
    yield put(createCustomerFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Создание клиента',
        defaultText: 'Не удалось создать клиента.',
      }),
    )
    console.error(`[createCustomerSaga]: ${error}`)
  }
}
