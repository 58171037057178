import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { TemplateState } from 'store/template/template.reducer'
import { selectTemplate } from 'store/template/template.selectors'
import { getTemplateRequest } from 'store/template/template.actions'
import { selectCurrentUser } from 'store/users/users.selectors'
import {
  selectCreateCurrentCompanyDetailsLoadingStatus,
  selectCurrentCompanyDetails,
  selectUpdateCurrentCompanyDetailsLoadingStatus,
} from 'store/billing/billing.selectors'
import {
  createCurrentCompanyDetailsRequest,
  getCurrentCompanyDetailsRequest,
  updateCurrentCompanyDetailsRequest,
} from 'store/billing/billing.actions'

import { TemplateType } from 'types/core/template'
import { Props } from 'types/core/props'

export const useBillingCompanyDetails = () => {
  const dispatch = useDispatch()
  const companyDetails = useSelector(selectCurrentCompanyDetails)
  const createLoadingStatus = useSelector(selectCreateCurrentCompanyDetailsLoadingStatus)
  const updateLoadingStatus = useSelector(selectUpdateCurrentCompanyDetailsLoadingStatus)
  const templateState: TemplateState = useSelector(selectTemplate)
  const currentUser = useSelector(selectCurrentUser)

  const [billingCompanyDetails, setBillingCurrentCompanyDetails] = useState<Props>({})
  const [initialDetails, setInitialDetails] = useState<Props>({})
  const [formState, setFormState] = useState<{ isVisible: boolean; isCreate: boolean }>({ isVisible: false, isCreate: false })

  useEffect(() => {
    dispatch(getCurrentCompanyDetailsRequest())
  }, [currentUser, dispatch])

  useEffect(() => {
    dispatch(getTemplateRequest({ name: TemplateType.COMPANY_DETAILS }))
  }, [dispatch])

  const handleOpen = useCallback(
    (isCreate: boolean = false) => {
      setFormState({ isVisible: true, isCreate })
      setBillingCurrentCompanyDetails(companyDetails)
      setInitialDetails(companyDetails)
    },
    [companyDetails],
  )

  const handleClose = useCallback(() => {
    setFormState({ isVisible: false, isCreate: false })
  }, [])

  const onChange = useCallback((value: any, id: any) => {
    setBillingCurrentCompanyDetails((prev) => ({ ...prev, [id]: value }))
  }, [])

  const [changedDetails, detailsIsChanged] = useMemo(() => {
    const entries = Object.entries(billingCompanyDetails)
    const filteredEntries = entries.filter(([key, value]) => value !== initialDetails[key])
    return [Object.fromEntries(filteredEntries), filteredEntries.length > 0]
  }, [initialDetails, billingCompanyDetails])

  const onSubmit = useCallback(() => {
    if (formState.isCreate) dispatch(createCurrentCompanyDetailsRequest(billingCompanyDetails))
    else dispatch(updateCurrentCompanyDetailsRequest({ company: changedDetails }))
    dispatch(getCurrentCompanyDetailsRequest())
  }, [formState.isCreate, dispatch, billingCompanyDetails, changedDetails])

  return {
    template: templateState.templates[TemplateType.COMPANY_DETAILS],
    createLoadingStatus,
    updateLoadingStatus,
    billingCompanyDetails,
    detailsIsChanged,
    handleOpen,
    handleClose,
    onChange,
    onSubmit,
    formState,
  }
}
