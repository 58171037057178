import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { LoadingStatus } from 'types/loading'
import { Template } from 'types/core/template'

import { createInvoice } from 'requests/billing'

import { selectCurrentUser, selectToken } from 'store/users/users.selectors'
import { selectCompanyAccount } from 'store/billing/billing.selectors'

import InputControl from 'components/molecules/InputControl'
import Button from 'components/molecules/Button'

import { useBillingCompanyDetails } from './hook/use-company-details'
import BillingCompanyDetailsForm from './billingCompanyDetailsForm'

const InvoiceForm: React.FC = () => {
  const token = useSelector(selectToken)
  const user = useSelector(selectCurrentUser)
  const companyAccount = useSelector(selectCompanyAccount)

  const [isLoading, setIsLoading] = useState(false)

  const [amount, setAmount] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const {
    createLoadingStatus,
    updateLoadingStatus,
    template,
    billingCompanyDetails,
    onChange,
    onSubmit,
    detailsIsChanged,
    handleOpen,
    handleClose,
    formState,
  } = useBillingCompanyDetails()

  const handleAmountChange = useCallback((value: string) => {
    setAmount(value)
    setErrorMessage('')
  }, [])

  const customerHasCompanyDetails = Boolean(user?.features?.companyId)

  const generateInvoice = useCallback(() => {
    const numericAmount = parseFloat(amount)
    if (numericAmount <= 0) {
      setErrorMessage('Пожалуйста, введите корректную сумму.')
    } else {
      setIsLoading(true)
      setAmount('')
      createInvoice({ amount }, token as string)
        .then((data) => {
          window.location.href = data.link
        })
        .finally(() => setIsLoading(false))
    }
  }, [amount, token])

  const handleDownload = useCallback(() => {
    if (user?.features?.companyId) generateInvoice()
    else handleOpen(true)
  }, [generateInvoice, handleOpen, user])

  useEffect(() => {
    if (createLoadingStatus === LoadingStatus.SUCCESS) handleClose()
  }, [createLoadingStatus, handleClose])

  if (!companyAccount?.id) return null

  return (
    <div>
      <div className="mb-2 mt-3 font-weight-bold">
        <InputControl
          type="number"
          id="invoiceAmount"
          className="col-lg-3 col-md-6 col-sm-12"
          value={amount}
          onChangeHandler={handleAmountChange}
          label="Введите сумму для формирования счета"
          placeholder="Введите сумму"
          invalidMessage={errorMessage}
        />
      </div>
      <div className="mb-2 font-weight-bold d-flex flex-column w-25">
        <Button onClick={handleDownload} disabled={!amount} isLoading={isLoading}>
          Скачать счет
        </Button>
        {customerHasCompanyDetails ? (
          <Button className="mt-1" variant="outline-primary" onClick={() => handleOpen()}>
            Изменить реквизиты
          </Button>
        ) : null}
      </div>
      <BillingCompanyDetailsForm
        isVisible={formState.isVisible}
        companyDetails={billingCompanyDetails}
        detailsIsChanged={detailsIsChanged}
        handleClose={handleClose}
        isLoading={createLoadingStatus === LoadingStatus.PENDING || updateLoadingStatus === LoadingStatus.PENDING}
        onChange={onChange}
        onSubmit={onSubmit}
        template={template as Template}
      />
    </div>
  )
}

export default InvoiceForm
