import { put, select, call } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'
import { updateUserFeatures } from 'store/users/users.actions'

import { Props } from 'types/core/props'

import { createCurrentCompanyDetails } from 'requests/company'
import { createCurrentCompanyDetailsFailure, createCurrentCompanyDetailsSuccess } from '../billing.actions'

export function* createCurrentCompanyDetailsSaga(action: Action<Props>) {
  try {
    const token = yield select(selectToken)

    const companyDetails = yield call(createCurrentCompanyDetails, action.payload, token)

    yield put(createCurrentCompanyDetailsSuccess(action.payload))
    yield put(updateUserFeatures({ companyId: companyDetails.id }))
  } catch (error) {
    yield put(createCurrentCompanyDetailsFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Создание реквизитов.',
        defaultText: 'Не удалось создать реквизиты.',
      }),
    )
    console.error(`[createCurrentCompanyDetailsSaga]: ${error}`)
  }
}
