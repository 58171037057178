import { fork, cancel, take } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import * as actions from 'store/order/order.actions'

import { fetchAutoCheckSaga } from './fetch-auto-check'

export function* tryToFetchAutoCheckSaga(action: Action<string>) {
  try {
    // Этот action используется и в других местах
    if (typeof action.payload !== 'string') return

    const task = yield fork(fetchAutoCheckSaga, action)

    if (yield take(actions.orderSetInitialState)) yield cancel(task)
  } catch (error) {
    console.error(`[tryToFetchAutoCheckSaga]: ${error}`)
  }
}
