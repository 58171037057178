import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { checkVersionRequest } from 'store/version/version.actions'
import { selectCurrentBillingSettings } from 'store/billing/billing.selectors'
import { selectCurrentUser } from 'store/users/users.selectors'
import { getCompanyAccountRequest } from 'store/billing/billing.actions'

import BillingHeader from 'components/organisms/BillingHeader/BillingHeader'
import Billing from 'components/molecules/Billing'
import Balance from 'components/molecules/Billing/balance'
import InvoiceForm from 'components/molecules/Billing/invoiceForm'
import ReportForm from 'components/molecules/Billing/ReportForm'
import PlugBilling from 'components/molecules/Billing/PlugBilling'

export const BillingPage: React.FC = () => {
  const dispatch = useDispatch()
  const settings = useSelector(selectCurrentBillingSettings)
  const currentUser = useSelector(selectCurrentUser)

  const userIsCustomerAdmin = currentUser?.features?.subrole === 'customer-admin'
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    dispatch(getCompanyAccountRequest('current'))
  }, [dispatch, currentUser])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    dispatch(checkVersionRequest())
  }, [dispatch])

  return (
    <div className="container page-content d-flex flex-column flex-grow-1">
      <BillingHeader />
      {userIsCustomerAdmin ? (
        <>
          <Balance />
          <InvoiceForm />
          <ReportForm />

          {settings?.billingFilesUrl ? <Billing url={settings?.billingFilesUrl as string} /> : null}
        </>
      ) : (
        <PlugBilling />
      )}

      {/* {settings?.billingUrl ? <Billing url={settings?.billingUrl as string} /> : null} */}
    </div>
  )
}

export default BillingPage
