import React, { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Switch } from 'react-router'
import { UserRole } from 'types/user'

import {
  adminPath,
  authPath,
  billingPath,
  chatsPath,
  checksPath,
  createOrderPath,
  editOrderPath,
  expertsOnlinePath,
  l1OnlinePath,
  orderPath,
  ordersListPath,
  searchOrderPath,
  statisticPath,
} from 'routes/url-constants'

import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'

import { fetchCurrentUserRequest } from 'store/users/users.actions'
import { isCurrentUserLoadingInProgress, selectCurrentUser, selectToken } from 'store/users/users.selectors'
import { getCurrentBillingSettingsRequest } from 'store/billing/billing.actions'

import { socketNotifications } from 'requests/socket'

import AuthPage from 'components/pages/AuthPage'
import OrderListPage from 'components/pages/OrderListPage'
import OrderPage from 'components/pages/OrderPage'
import CreateOrderPage from 'components/pages/CreateOrderPage'
import EditOrderPage from 'components/pages/EditOrderPage'
import SearchOrderPage from 'components/pages/SearchOrderPage'
import StatisticPage from 'components/pages/StatisticPage'
import BillingPage from 'components/pages/BillingPage'
import UserSchedulerPage from 'components/pages/UserSchedulerPage'

import { PrivateRoute } from './private-route'
import { ErrorBoundary } from './error-boundary'
import { AuthRoute } from './auth-route'

const AdminPage = React.lazy(() => import('components/pages/AdminPage'))
const ChecksPage = React.lazy(() => import('components/pages/ChecksPage'))
const ChatsPage = React.lazy(() => import('components/pages/ChatsPage'))

export type RoutesProps = {}
export const Routes: React.FC<RoutesProps> = () => {
  const dispatch = useDispatch()
  const token = useSelector(selectToken)
  const currentUser = useSelector(selectCurrentUser)
  const userIsLoading = useSelector(isCurrentUserLoadingInProgress)

  useEffect(() => {
    dispatch(fetchCurrentUserRequest())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentUser?.role === UserRole.CUSTOMER) dispatch(getCurrentBillingSettingsRequest())
  }, [currentUser, dispatch])

  useEffect(() => {
    if (token) {
      socketNotifications.emit('auth', token)

      socketNotifications.on('reconnect', () => {
        console.log(`socket reconnecting`)
        socketNotifications.emit('auth', token)
      })
    }
  }, [token])

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingPlaceholder />}>
        <Switch>
          <PrivateRoute exact authorizedUserRoles={[UserRole.ADMIN]} redirectUnauthorizedTo={authPath} path={adminPath} component={AdminPage} />

          <PrivateRoute exact authorizedUserRoles={[UserRole.EXPERT]} redirectUnauthorizedTo={authPath} path={checksPath} component={ChecksPage} />

          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.ADMIN, UserRole.EXPERT, UserRole.MANAGER, UserRole.CUSTOMER]}
            redirectUnauthorizedTo={authPath}
            path={createOrderPath}
            component={CreateOrderPage}
          />

          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.ADMIN, UserRole.EXPERT, UserRole.MANAGER]}
            redirectUnauthorizedTo={authPath}
            path={editOrderPath}
            component={EditOrderPage}
          />

          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.ADMIN, UserRole.EXPERT, UserRole.MANAGER, UserRole.CUSTOMER]}
            redirectUnauthorizedTo={authPath}
            path={orderPath}
            component={OrderPage}
          />
          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.ADMIN, UserRole.EXPERT, UserRole.MANAGER, UserRole.CUSTOMER]}
            redirectUnauthorizedTo={authPath}
            path={`${orderPath}/chat`}
            component={OrderPage}
          />
          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.EXPERT]}
            redirectUnauthorizedTo={authPath}
            path={`${orderPath}/certificate`}
            component={OrderPage}
          />
          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.EXPERT]}
            redirectUnauthorizedTo={authPath}
            path={`${orderPath}/order-history`}
            component={OrderPage}
          />
          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.ADMIN, UserRole.EXPERT, UserRole.MANAGER]}
            redirectUnauthorizedTo={authPath}
            path={`${orderPath}/internal-chat`}
            component={OrderPage}
          />
          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.ADMIN, UserRole.EXPERT, UserRole.MANAGER]}
            redirectUnauthorizedTo={authPath}
            path={`${orderPath}/auto-checks`}
            component={OrderPage}
          />

          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.ADMIN, UserRole.EXPERT, UserRole.MANAGER, UserRole.CUSTOMER]}
            redirectUnauthorizedTo={authPath}
            path={ordersListPath}
            component={OrderListPage}
          />

          {userIsLoading || currentUser?.features?.subrole === 'customer-admin' ? (
            <PrivateRoute
              exact
              authorizedUserRoles={[UserRole.CUSTOMER]}
              redirectUnauthorizedTo={authPath}
              path={billingPath}
              component={BillingPage}
            />
          ) : null}

          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.ADMIN, UserRole.EXPERT, UserRole.MANAGER, UserRole.CUSTOMER]}
            redirectUnauthorizedTo={authPath}
            path={searchOrderPath}
            component={SearchOrderPage}
          />

          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.ADMIN, UserRole.EXPERT, UserRole.MANAGER]}
            redirectUnauthorizedTo={authPath}
            path={statisticPath}
            component={StatisticPage}
          />

          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.ADMIN, UserRole.EXPERT, UserRole.MANAGER]}
            redirectUnauthorizedTo={authPath}
            path={chatsPath}
            component={ChatsPage}
          />

          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.EXPERT]}
            redirectUnauthorizedTo={authPath}
            path={expertsOnlinePath}
            component={UserSchedulerPage}
          />

          <PrivateRoute
            exact
            authorizedUserRoles={[UserRole.MANAGER]}
            redirectUnauthorizedTo={authPath}
            path={l1OnlinePath}
            component={UserSchedulerPage}
          />

          <AuthRoute exact redirectAuthorizedTo={ordersListPath} path={authPath} component={AuthPage} />
          <Redirect to={ordersListPath} />
          {/* @TODO implement routes */}
        </Switch>
      </Suspense>
    </ErrorBoundary>
  )
}
