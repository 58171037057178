import { call, put, select } from 'redux-saga/effects'

import { fetchCreateOrder } from 'requests/order'
import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { OrderFormData, CreateOrderResponseData, OrderFields } from 'types/order'

import { selectOrderWrite } from '../order.selectors'
import { fetchCreateOrderFailure, fetchCreateOrderSuccess } from '../order.actions'

export function* fetchCreateOrderSaga() {
  try {
    const token = yield select(selectToken)
    const form: OrderFormData = yield select(selectOrderWrite)

    const response: CreateOrderResponseData = yield call(fetchCreateOrder, form, token)

    yield put(
      addAlert({
        text: `Заявка ${response.fields[OrderFields.ORDER_NUMBER]} успешно создана.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Создание заявки',
      }),
    )
    yield put(fetchCreateOrderSuccess(response))
  } catch (error) {
    yield put(
      addHttpError({
        error,
        header: 'Создание заявки',
        defaultText: 'Не удалось создать заявку.',
      }),
    )
    console.error(`[fetchCreateOrderSaga]: ${error}`)
    yield put(fetchCreateOrderFailure(error))
  }
}
