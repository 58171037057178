export enum CountryCode {
  RU = 'ru',
  KZ = 'kz',
  UA = 'ua',
  KG = 'kg',
  BY = 'by',
  UZ = 'uz',
}

export const CountryName = {
  [CountryCode.RU]: 'Россия',
  [CountryCode.KZ]: 'Казахстан',
  [CountryCode.BY]: 'Беларусь',
  [CountryCode.KG]: 'Кыргызстан',
  [CountryCode.UA]: 'Украина',
  [CountryCode.UZ]: 'Узбекистан',
}

export const CountryPlaceholder = {
  [CountryCode.RU]: 'A000AA000',
  [CountryCode.KZ]: '000AAA00',
  [CountryCode.BY]: 'A0000AA',
  [CountryCode.KG]: '00AA000AAA',
  [CountryCode.UA]: 'AA00001',
  [CountryCode.UZ]: '000000AA',
}

export const translationMap: { [key: string]: string } = {
  А: 'A',
  В: 'B',
  Е: 'E',
  К: 'K',
  М: 'M',
  Н: 'H',
  О: 'O',
  Р: 'P',
  С: 'C',
  Т: 'T',
  У: 'Y',
  Х: 'X',
}
