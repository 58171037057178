import { createHash } from 'crypto'

export class ValuesHash {
  public static hash(values: string[], separator: string = '-'): string {
    const str = values.join(separator)
    const shaSum = createHash('sha1')
    shaSum.update(str)
    return shaSum.digest('hex')
  }
}
