import { put, select, call } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { Props } from 'types/core/props'

import { getCompaniesRequest, setInitialAdminState, updateCompanyDetailsFailure, updateCompanyDetailsSuccess } from 'store/admin/admin.actions'
import { patchCompanyDetails } from 'requests/company'

export function* updateCompanyDetailsSaga(action: Action<{ companyId: string; company: Props }>) {
  try {
    const token = yield select(selectToken)

    yield call(patchCompanyDetails, action.payload.companyId, action.payload.company, token)

    yield put(updateCompanyDetailsSuccess())
    yield put(
      addAlert({
        text: `Реквизиты изменены.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Панель администратора',
      }),
    )
    yield put(setInitialAdminState())
    yield put(getCompaniesRequest())
  } catch (error) {
    yield put(updateCompanyDetailsFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Изменение реквизитов.',
        defaultText: 'Не удалось изменить реквизиты.',
      }),
    )
    console.error(`[updateCompanyDetailsSaga]: ${error}`)
  }
}
