import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { addAlert } from 'store/alerts/alerts.actions'
import { AlertVariant } from 'types/alert'
import { LoadingPlaceholder } from 'components/atoms/LoadingPlaceholder'

import classes from './style.module.css'

interface ChatProps {
  isInternal?: boolean
  orderNumber: string
  token: string | undefined
}

const Chat: React.FC<ChatProps> = ({ isInternal = false, orderNumber, token = '' }) => {
  const dispatchStore = useDispatch()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const tagStyle = document.createElement('style')
    tagStyle.innerHTML = 'pf-widget {display: none;}'
    document.head.appendChild(tagStyle)
    return () => {
      document.head.removeChild(tagStyle)
    }
  }, [])

  const onLoad = () => {
    setIsLoaded(true)
  }

  const onError = () => {
    dispatchStore(
      addAlert({
        header: 'Чат',
        text: 'Не удалось загрузить чат',
        lifetime: 6000,
        variant: AlertVariant.DANGER,
      }),
    )
  }

  const src = `${process.env.REACT_APP_CHAT}/?isInternal=${isInternal}&orderNumber=${orderNumber}`
  return (
    <div className={classes.container}>
      <div className={classes.chat}>
        <iframe
          key={src}
          src={`${src}&token=${token}&randomNumber=${Date.now()}`}
          width="100%"
          height="100%"
          frameBorder="0"
          className={classNames(isLoaded ? classes.visible : classes.hidden)}
          title="Чат"
          onLoad={onLoad}
          onError={onError}
        />
        {isLoaded ? null : <LoadingPlaceholder />}
      </div>
    </div>
  )
}

export default Chat
