import { put, select, call } from 'redux-saga/effects'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'

import { TelegramChat } from 'types/telegram-chat'

import { fetchGetTelegramChatList } from 'requests/telegram-chat-list'

import { fetchGetTelegramChatListSuccess, fetchGetTelegramChatListFailure } from '../telegramChatList.actions'

export function* fetchGetTelegramChatListSaga() {
  try {
    const token = yield select(selectToken)

    const list: TelegramChat[] = yield call(fetchGetTelegramChatList, token)

    yield put(fetchGetTelegramChatListSuccess(list))
  } catch (error) {
    yield put(fetchGetTelegramChatListFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение списка телеграм чатов',
        defaultText: 'Не удалось получить список телеграм чатов.',
      }),
    )
    console.error(`[fetchGetTelegramChatListSaga]: ${error}`)
  }
}
