import { Order, OrderFields } from 'types/order'
import { groupOrders } from './group-orders'

export class YandexTaxiGroupSorter {
  static sort(orders: Order[]) {
    const [yandexOrders, regularOrders] = groupOrders(orders, YandexTaxiGroupSorter.isYandexOrder)

    return [...regularOrders, ...yandexOrders]
  }

  static isYandexOrder(order: Order) {
    return String(order[OrderFields.CLIENT_NAME]) === process.env.REACT_APP_YANDEX_TAXI_ID_CLIENT
  }
}
