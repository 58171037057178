import { put, select, call } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'
import { createCompanyDetailsSuccess, createCompanyDetailsFailure, getCompaniesRequest, setInitialAdminState } from 'store/admin/admin.actions'

import { AlertVariant } from 'types/alert'
import { Props } from 'types/core/props'

import { createCompanyDetails } from 'requests/company'

export function* createCompanyDetailsSaga(action: Action<Props>) {
  try {
    const token = yield select(selectToken)

    yield call(createCompanyDetails, action.payload, token)

    yield put(createCompanyDetailsSuccess())
    yield put(
      addAlert({
        text: `Реквизиты успешно созданы.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Создание реквизитов',
      }),
    )
    yield put(setInitialAdminState())
    yield put(getCompaniesRequest())
  } catch (error) {
    yield put(createCompanyDetailsFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Создание реквизитов',
        defaultText: 'Не удалось создать реквизиты.',
      }),
    )
    console.error(`[createCompanyDetailsSaga]: ${error}`)
  }
}
