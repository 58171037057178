import { put, select, call } from 'redux-saga/effects'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'

import { EntityValue } from 'types/core/entity-value'

import { getCompanies } from 'requests/company'

import { getCompaniesSuccess, getCompaniesFailure } from 'store/admin/admin.actions'

export function* getCompaniesSaga() {
  try {
    const token = yield select(selectToken)

    const companies: EntityValue[] = yield call(getCompanies, token)

    yield put(getCompaniesSuccess(companies))
  } catch (error) {
    yield put(getCompaniesFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение списка компаний',
        defaultText: 'Не удалось получить компании.',
      }),
    )
    console.error(`[getCompaniesSaga]: ${error}`)
  }
}
