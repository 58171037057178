import { Action, handleActions } from 'redux-actions'

import { Template, TemplateType } from 'types/core/template'
import { LoadingStatus } from 'types/loading'
import * as actions from 'store/template/template.actions'

export interface TemplateState {
  templates: { [key in TemplateType]?: Template }
  loadingStatus: LoadingStatus
}

export const initialTemplateState: TemplateState = {
  templates: {},
  loadingStatus: LoadingStatus.NONE,
}

const getTemplateRequest = (state: TemplateState): TemplateState => {
  return {
    ...state,
    loadingStatus: LoadingStatus.PENDING,
  }
}

const getTemplateSuccess = (state: TemplateState, action: Action<Template>): TemplateState => {
  return {
    ...state,
    templates: { ...state.templates, [action.payload.name]: action.payload },
    loadingStatus: LoadingStatus.SUCCESS,
  }
}

const getTemplateFailure = (state: TemplateState): TemplateState => {
  return {
    ...state,
    loadingStatus: LoadingStatus.FAILED,
  }
}

export const templateReducer = handleActions<TemplateState, any>(
  {
    [actions.GET_TEMPLATE_REQUEST]: getTemplateRequest,
    [actions.GET_TEMPLATE_SUCCESS]: getTemplateSuccess,
    [actions.GET_TEMPLATE_FAILURE]: getTemplateFailure,
  },
  initialTemplateState,
)
