import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'

import { useDocumentPreviews } from 'components/molecules/OrderFormSpace/hooks/useDocumentPreviews'

import { LogisticType, OrderFields, OrderTypeId } from 'types/order'
import { LoadingStatus } from 'types/loading'
import { FileDataType } from 'types/file'

import { selectOrderWrite, selectOrderFormErrors, selectOrderLogisticInfoStatus } from 'store/order/order.selectors'
import { fetchOrderGetLogisticInfoRequest } from 'store/order/order.actions'

import InputControl from 'components/molecules/InputControl'
import InputDate from 'components/molecules/InputDate'
import InputPhone from 'components/molecules/InputPhone'
import Gallery from 'components/molecules/Gallery'
import SwitchControl from 'components/molecules/SwitchControl'
import Title from 'components/atoms/Title'
import RelatedField from 'components/molecules/RelatedField'

import LoadingProgressIndeterminate from 'components/atoms/LoadingProgressIndeterminate'

import { getTitleForCarrierByOrderType } from 'utils/title'
import { LogisticProps } from './index'
import classes from './style.module.css'

const Manager: React.FC<LogisticProps> = ({ onChange }) => {
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)
  const loadingLogisticInfoStatus = useSelector(selectOrderLogisticInfoStatus)
  const dispatch = useDispatch()

  const onBlurINN = useCallback(() => {
    if (
      (form[OrderFields.LOGISTIC_INN].length === 10 || form[OrderFields.LOGISTIC_INN].length === 12) &&
      (form[OrderFields.LOGISTIC_TYPE] === LogisticType.LEGAL || form[OrderFields.LOGISTIC_TYPE] === LogisticType.INDIVIDUAL)
    )
      dispatch(fetchOrderGetLogisticInfoRequest())
  }, [dispatch, form])

  const changeLogisticType = useCallback(
    (type: LogisticType) => {
      onChange(type, OrderFields.LOGISTIC_TYPE)

      if (
        (form[OrderFields.LOGISTIC_INN].length === 10 || form[OrderFields.LOGISTIC_INN].length === 12) &&
        (type === LogisticType.LEGAL || type === LogisticType.INDIVIDUAL)
      ) {
        dispatch(fetchOrderGetLogisticInfoRequest())
      }
    },
    [onChange, form, dispatch],
  )

  const documentPreviews = useDocumentPreviews(form)

  const orderTypeId = String(form[OrderFields.ORDER_TYPE])
  const titleText = getTitleForCarrierByOrderType(orderTypeId as OrderTypeId)

  return (
    <>
      <Title text={titleText} />
      <ul className="list-group needs-validation">
        <li className="list-group-item row">
          <div className="d-flex align-items-top flex-wrap">
            <div className="d-inline-block col-lg-4 col-md-6 col-sm-12 p-0">
              <InputControl
                type="number"
                id={OrderFields.LOGISTIC_INN}
                value={form[OrderFields.LOGISTIC_INN]}
                isValid={form[OrderFields.LOGISTIC_TYPE] === LogisticType.FOREIGN ? undefined : errors[OrderFields.LOGISTIC_INN].isValid}
                invalidMessage={errors[OrderFields.LOGISTIC_INN].invalidMessage}
                onChangeHandler={onChange}
                onBlurHandler={onBlurINN}
                label="ИНН:"
                disabled={loadingLogisticInfoStatus === LoadingStatus.PENDING}
              />
            </div>
            <div className={classes.hasBelarusians}>
              <SwitchControl
                id={OrderFields.HAS_BELARUSIANS}
                value={form[OrderFields.HAS_BELARUSIANS]}
                onChangeHandler={onChange}
                label="В заявке есть белорусы"
              />
            </div>
          </div>
          <div>
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '12px' }}>
              <Button
                className="p-0"
                style={{ flex: '1' }}
                disabled={form[OrderFields.LOGISTIC_TYPE] === LogisticType.SELF_EMPLOYED || loadingLogisticInfoStatus === LoadingStatus.PENDING}
                onClick={() => changeLogisticType(LogisticType.SELF_EMPLOYED)}
              >
                Самозанятый
              </Button>
              <Button
                className="p-0"
                style={{ flex: '1', marginLeft: '4px' }}
                disabled={form[OrderFields.LOGISTIC_TYPE] === LogisticType.FOREIGN || loadingLogisticInfoStatus === LoadingStatus.PENDING}
                onClick={() => changeLogisticType(LogisticType.FOREIGN)}
              >
                Иност. компания
              </Button>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '4px' }}>
              <Button
                className="p-0 w-100"
                disabled={
                  form[OrderFields.LOGISTIC_TYPE] === LogisticType.LEGAL ||
                  form[OrderFields.LOGISTIC_TYPE] === LogisticType.INDIVIDUAL ||
                  loadingLogisticInfoStatus === LoadingStatus.PENDING
                }
                onClick={() => changeLogisticType(form[OrderFields.LOGISTIC_INN].length === 12 ? LogisticType.INDIVIDUAL : LogisticType.LEGAL)}
              >
                Юр.лицо или ИП
              </Button>
            </div>
            {loadingLogisticInfoStatus === LoadingStatus.PENDING ? (
              <div style={{ marginTop: '12px' }}>
                <LoadingProgressIndeterminate />
              </div>
            ) : null}
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              value={form[OrderFields.LOGISTIC_NAME]}
              isValid={errors[OrderFields.LOGISTIC_NAME].isValid}
              invalidMessage={errors[OrderFields.LOGISTIC_NAME].invalidMessage}
              type="text"
              id={OrderFields.LOGISTIC_NAME}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Название юрлица:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              value={form[OrderFields.LOGISTIC_OGRN]}
              isValid={form[OrderFields.LOGISTIC_TYPE] === LogisticType.FOREIGN ? undefined : errors[OrderFields.LOGISTIC_OGRN].isValid}
              invalidMessage={errors[OrderFields.LOGISTIC_OGRN].invalidMessage}
              type="text"
              id={OrderFields.LOGISTIC_OGRN}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="ОГРН(ИП):"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className={classes.controlWithGallery}>
            <div className="p-0 w-100 col-lg-4 col-md-6 col-sm-12">
              <InputControl
                value={form[OrderFields.LOGISTIC_FIRST_FACE]}
                isValid={errors[OrderFields.LOGISTIC_FIRST_FACE].isValid}
                invalidMessage={errors[OrderFields.LOGISTIC_FIRST_FACE].invalidMessage}
                type="text"
                id={OrderFields.LOGISTIC_FIRST_FACE}
                onChangeHandler={onChange}
                className="w-100"
                label="1е лицо, ФИО:"
              />
            </div>
            <div className={classes.galleryWrapper}>
              <Gallery
                images={[...(documentPreviews?.[FileDataType.PASSPORT_NAME] || []), ...(documentPreviews?.[FileDataType.PASSPORT_REG] || [])]}
                isRightSide
                classNameForItem={classes.galleryItem}
              />
            </div>
          </div>
          <div className="w-100">
            <RelatedField
              fieldName={OrderFields.LOGISTIC_FIRST_FACE}
              fieldToCopy={OrderFields.LOGISTIC_FIO}
              relatedFields={{
                [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: OrderFields.LOGISTIC_TEL,
                [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: OrderFields.LOGISTIC_TEL_2,
              }}
            />
            <RelatedField
              fieldName={OrderFields.LOGISTIC_FIRST_FACE}
              fieldToCopy={OrderFields.DRIVER_FIO}
              relatedFields={{
                [OrderFields.CARRIER_FIRST_FACE_PHONE_1]: OrderFields.DRIVER_TEL_1,
                [OrderFields.CARRIER_FIRST_FACE_PHONE_2]: OrderFields.DRIVER_TEL_2,
                [OrderFields.LOGISTIC_FIRST_FACE_BITH]: OrderFields.DRIVER_BITH,
              }}
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputDate
              value={form[OrderFields.LOGISTIC_FIRST_FACE_BITH]}
              isValid={errors[OrderFields.LOGISTIC_FIRST_FACE_BITH].isValid}
              invalidMessage={errors[OrderFields.LOGISTIC_FIRST_FACE_BITH].invalidMessage}
              id={OrderFields.LOGISTIC_FIRST_FACE_BITH}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="1е лицо. Д.Р.:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              value={form[OrderFields.LOGISTIC_FIRST_FACE_INN]}
              isValid={errors[OrderFields.LOGISTIC_FIRST_FACE_INN].isValid}
              invalidMessage={errors[OrderFields.LOGISTIC_FIRST_FACE_INN].invalidMessage}
              id={OrderFields.LOGISTIC_FIRST_FACE_INN}
              type="number"
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="1е лицо, ИНН:"
            />
          </div>
        </li>
        <li className="list-group-item row">
          <a id="linkReputation" target="_blank" rel="noopener noreferrer" href={`https://reputation.ru/${form[OrderFields.LOGISTIC_OGRN]}`}>
            {`https://reputation.ru/${form[OrderFields.LOGISTIC_OGRN] ? form[OrderFields.LOGISTIC_OGRN] : ''}`}
          </a>
        </li>
        {form[OrderFields.LOGISTIC_INN]?.length === 9 ? (
          <li className="list-group-item row">
            <a id="linkReputation" target="_blank" rel="noopener noreferrer" href={`https://kartoteka.by/unp-${form[OrderFields.LOGISTIC_INN]}`}>
              {`https://kartoteka.by/unp-${form[OrderFields.LOGISTIC_INN] ? form[OrderFields.LOGISTIC_INN] : ''}`}
            </a>
          </li>
        ) : null}
        <li className="list-group-item row">
          <div className="p-0">
            <InputControl
              value={form[OrderFields.LOGISTIC_ATI]}
              isValid={form[OrderFields.LOGISTIC_ATI_STATUS] ? errors[OrderFields.LOGISTIC_ATI].isValid : undefined}
              invalidMessage={errors[OrderFields.LOGISTIC_ATI].invalidMessage}
              disabled={!form[OrderFields.LOGISTIC_ATI_STATUS]}
              id={OrderFields.LOGISTIC_ATI}
              type="number"
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="АТИ:"
            />
          </div>
          <div>
            <div className="col-lg-4 col-md-6 col-sm-12 p-0 d-flex" style={{ marginTop: '12px' }}>
              <Button
                className="p-0"
                style={{ flex: '1', marginRight: '4px' }}
                disabled={form[OrderFields.LOGISTIC_ATI_STATUS]}
                onClick={() => onChange(true, OrderFields.LOGISTIC_ATI_STATUS)}
              >
                Есть в АТИ
              </Button>
              <Button
                className="p-0"
                style={{ flex: '1', marginLeft: '4px' }}
                disabled={!form[OrderFields.LOGISTIC_ATI_STATUS]}
                onClick={() => {
                  onChange(false, OrderFields.LOGISTIC_ATI_STATUS)
                  onChange('', OrderFields.LOGISTIC_ATI)
                }}
              >
                Нет в АТИ
              </Button>
            </div>
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputPhone
              value={form[OrderFields.CARRIER_FIRST_FACE_PHONE_1]}
              isValid={errors[OrderFields.CARRIER_FIRST_FACE_PHONE_1].isValid}
              invalidMessage={errors[OrderFields.CARRIER_FIRST_FACE_PHONE_1].invalidMessage}
              id={OrderFields.CARRIER_FIRST_FACE_PHONE_1}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Телефон первого лица:"
            />
          </div>
          <div className="w-100">
            <RelatedField fieldName={OrderFields.CARRIER_FIRST_FACE_PHONE_1} fieldToCopy={OrderFields.LOGISTIC_TEL} relatedFields={{}} />
            <RelatedField fieldName={OrderFields.CARRIER_FIRST_FACE_PHONE_1} fieldToCopy={OrderFields.DRIVER_TEL_1} relatedFields={{}} />
          </div>
        </li>
        <li className="list-group-item row">
          <div className="p-0">
            <InputPhone
              value={form[OrderFields.CARRIER_FIRST_FACE_PHONE_2]}
              isValid={errors[OrderFields.CARRIER_FIRST_FACE_PHONE_2].isValid}
              invalidMessage={errors[OrderFields.CARRIER_FIRST_FACE_PHONE_2].invalidMessage}
              id={OrderFields.CARRIER_FIRST_FACE_PHONE_2}
              onChangeHandler={onChange}
              className="col-lg-4 col-md-6 col-sm-12"
              label="Второй телефон первого лица:"
            />
          </div>
          <div className="w-100">
            <RelatedField fieldName={OrderFields.CARRIER_FIRST_FACE_PHONE_2} fieldToCopy={OrderFields.LOGISTIC_TEL_2} relatedFields={{}} />
            <RelatedField fieldName={OrderFields.CARRIER_FIRST_FACE_PHONE_2} fieldToCopy={OrderFields.DRIVER_TEL_2} relatedFields={{}} />
          </div>
        </li>
      </ul>
    </>
  )
}

export default Manager
