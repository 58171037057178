import { createAction } from 'redux-actions'
import { DGQuery } from 'types/data-gateway'

export const FETCH_DATA_GATEWAY_REQUEST = 'FETCH_DATA_GATEWAY_REQUEST'
export const fetchDataGatewayRequest = createAction<DGQuery>(FETCH_DATA_GATEWAY_REQUEST)

export const FETCH_DATA_GATEWAY_SUCCESS = 'FETCH_DATA_GATEWAY_SUCCESS'
export const fetchDataGatewaySuccess = createAction<void>(FETCH_DATA_GATEWAY_SUCCESS)

export const FETCH_DATA_GATEWAY_FAILURE = 'FETCH_DATA_GATEWAY_FAILURE'
export const fetchDataGatewayFailure = createAction<Error>(FETCH_DATA_GATEWAY_FAILURE)

export const SET_INITIAL_DATA_GATEWAY_STATE = 'SET_INITIAL_DATA_GATEWAY_STATE'
export const setInitialDataGatewayState = createAction<void>(SET_INITIAL_DATA_GATEWAY_STATE)
