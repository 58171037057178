import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Button, Spinner } from 'react-bootstrap'
import { selectLoadingClarifyFormStatus, selectOrderRead } from 'store/order/order.selectors'
import { selectCurrentUser } from 'store/users/users.selectors'
import { OrderFields, ResolutionId } from 'types/order'
import { UserRole } from 'types/user'
import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'
import TextareaControl from 'components/molecules/TextareaControl'
import { LoadingStatus } from 'types/loading'
import { fetchClarifyDataRequest } from 'store/order/order.actions'

export const DataClarification: React.FC = () => {
  const dispatch = useDispatch()
  const fields = useSelector(selectOrderRead)
  const { orderNumber } = useParams()
  const loadingStatus = useSelector(selectLoadingClarifyFormStatus)
  const currentUser = useSelector(selectCurrentUser)

  const [form, setForm] = useState({
    comment: '',
  })

  const onChange = useCallback((value: string, id: string) => {
    setForm((prev) => ({
      ...prev,
      [id]: value,
    }))
  }, [])

  const onSubmit = useCallback(() => {
    dispatch(
      fetchClarifyDataRequest({
        [OrderFields.ORDER_NUMBER]: orderNumber,
        fields: {
          ...form,
        },
      }),
    )
  }, [dispatch, form, orderNumber])

  if (`${fields[OrderFields.RESOLUTION]}` !== `${ResolutionId.CLARIFY_DATA}` || currentUser?.role !== UserRole.CUSTOMER) return null

  return (
    <ul className="list-group row needs-validation">
      <li className="list-group-item">
        <div className="jumbotron" style={{ marginBottom: 0 }}>
          <h1 className="display-4">Пожалуйста, уточните данные</h1>
          <p className="lead">
            {fields[OrderFields.RESOLUTION_COMMENT] ? <TextWithLineBreaks text={fields[OrderFields.RESOLUTION_COMMENT]} /> : null}
          </p>
          <hr className="my-4" />
          <div style={{ marginBottom: '10px' }}>
            <TextareaControl value={form.comment} id="comment" onChangeHandler={onChange} placeholder="Комментарий" />
          </div>
          <Button
            onClick={onSubmit}
            className="btn btn-primary col-lg-3 col-md-6 col-sm-12"
            style={{ marginBottom: '10px' }}
            disabled={loadingStatus === LoadingStatus.PENDING}
          >
            {loadingStatus === LoadingStatus.PENDING ? (
              <>
                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                &nbsp; Загрузка..
              </>
            ) : (
              <>Отправить</>
            )}
          </Button>
        </div>
      </li>
    </ul>
  )
}
