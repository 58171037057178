import { put, select, call } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { selectToken } from 'store/users/users.selectors'
import { addAlert } from 'store/alerts/alerts.actions'
import { addHttpError } from 'store/http-error/http-error.actions'

import { AlertVariant } from 'types/alert'
import { EditUserData } from 'types/user'

import { fetchEditUser } from 'requests/user'

import { fetchEditUserSuccess, fetchEditUserFailure, fetchGetUsersRequest } from 'store/admin/admin.actions'

export function* fetchEditUserSaga(action: Action<EditUserData>) {
  try {
    const token = yield select(selectToken)

    yield call(fetchEditUser, action.payload, token)

    yield put(fetchEditUserSuccess())
    yield put(
      addAlert({
        text: `Пользователь успешно изменён.`,
        lifetime: 3000,
        variant: AlertVariant.SUCCESS,
        header: 'Панель администратора',
      }),
    )
    yield put(fetchGetUsersRequest())
  } catch (error) {
    yield put(fetchEditUserFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Редактирование пользователя',
        defaultText: 'Не удалось отредактировать пользователя.',
      }),
    )
    console.error(`[fetchEditUserSaga]: ${error}`)
  }
}
