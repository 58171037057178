import { call, put } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { login, loginFiles } from 'requests/user'
import { LoginData, User } from 'types/user'

import { addHttpError } from 'store/http-error/http-error.actions'

import { fetchCurrentUserRequest, fetchLoginFailure, fetchLoginSuccess } from '../users.actions'

function setCurrentUser(user: User) {
  localStorage.setItem('token', user.token || '')
}

export function* fetchLoginSaga(data: Action<LoginData>) {
  try {
    const { fields } = yield call(login, data.payload)

    yield call(loginFiles, fields.token)

    setCurrentUser(fields)

    yield put(fetchLoginSuccess())
    yield put(fetchCurrentUserRequest())
  } catch (error) {
    yield put(fetchLoginFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Авторизация',
        withoutDefaultAlert: true,
        textForCase: {
          401: 'Пользователь с таким логином или паролем не найден.',
        },
      }),
    )
    console.error(`[fetchLoginSaga]: ${error}`)
  }
}
