import React from 'react'
import { useSelector } from 'react-redux'

import { OrderFields } from 'types/order'
import { Feature, UserRole } from 'types/user'

import { selectOrderWrite, selectOrderFormErrors } from 'store/order/order.selectors'
import { selectSelectLists } from 'store/selectLists/selectLists.selectors'
import { selectCurrentUser } from 'store/users/users.selectors'

import SelectControl from 'components/molecules/SelectControl'
import TextareaControl from 'components/molecules/TextareaControl'
import TextWithLineBreaks from 'components/atoms/TextWithLineBreaks'

import { getTextOfDataRequestTemplate } from 'utils/order'

import { useOrderTaking } from 'hooks/use-order-taking'
import { HeaderProps } from './index'

const Manager: React.FC<HeaderProps> = ({ onChange, isCreate = false }) => {
  const form = useSelector(selectOrderWrite)
  const errors = useSelector(selectOrderFormErrors)
  const selectLists = useSelector(selectSelectLists)
  const currentUser = useSelector(selectCurrentUser)
  const { canSelectOrderToTake } = useOrderTaking()

  return (
    <>
      <ul className="list-group needs-validation" style={{ margin: '0 -15px' }}>
        {isCreate ? (
          <li className="list-group-item">
            <div className="p-0">
              <SelectControl
                value={form[OrderFields.EXECUTOR]}
                isValid={errors[OrderFields.EXECUTOR].isValid}
                invalidMessage={errors[OrderFields.EXECUTOR].invalidMessage}
                options={selectLists[OrderFields.EXECUTOR]}
                id={OrderFields.EXECUTOR}
                onChangeHandler={onChange}
                className="col-lg-4 col-md-6 col-sm-12"
                label="Исполнитель:"
                withEmptyValue
              />
            </div>
          </li>
        ) : (
          <>
            {form[OrderFields.DRIVER_CREDIT_RATING] && currentUser?.role === UserRole.MANAGER ? (
              <div className="p-3 mb-2 bg-danger text-white text-center">Запросили кредитный рейтинг, перевести на V. Seskutov</div>
            ) : null}
            {form?.[OrderFields.CUSTOMER_FEATURES]?.[Feature.IMPORTANT_INSTRUCTIONS_FOR_l1] ? (
              <div className="p-3 mb-2 bg-danger text-white text-center">
                <TextWithLineBreaks text={form?.[OrderFields.CUSTOMER_FEATURES]?.[Feature.IMPORTANT_INSTRUCTIONS_FOR_l1]} />
              </div>
            ) : null}
            <li className="list-group-item">
              <div className="p-0">
                <SelectControl
                  value={form[OrderFields.EXECUTOR_L1]}
                  isValid={errors[OrderFields.EXECUTOR_L1].isValid}
                  invalidMessage={errors[OrderFields.EXECUTOR_L1].invalidMessage}
                  options={(selectLists[OrderFields.EXECUTOR] || []).filter((item) => item.role === UserRole.MANAGER)}
                  id={OrderFields.EXECUTOR_L1}
                  onChangeHandler={onChange}
                  className="col-lg-4 col-md-6 col-sm-12"
                  label="Исполнитель L1:"
                  withEmptyValue
                />
              </div>
            </li>
            {canSelectOrderToTake && (
              <li className="list-group-item">
                <div className="p-0">
                  <SelectControl
                    value={form[OrderFields.EXECUTOR_EXPERT]}
                    isValid={errors[OrderFields.EXECUTOR_EXPERT].isValid}
                    invalidMessage={errors[OrderFields.EXECUTOR_EXPERT].invalidMessage}
                    options={(selectLists[OrderFields.EXECUTOR] || []).filter((item) => item.role === UserRole.EXPERT)}
                    id={OrderFields.EXECUTOR_EXPERT}
                    onChangeHandler={onChange}
                    className="col-lg-4 col-md-6 col-sm-12"
                    label="Исполнитель E:"
                    withEmptyValue
                  />
                </div>
              </li>
            )}
          </>
        )}
        <li className="list-group-item">
          <TextareaControl
            value={form[OrderFields.ORDER_COMMENT]}
            isValid={errors[OrderFields.ORDER_COMMENT].isValid}
            invalidMessage={errors[OrderFields.ORDER_COMMENT].invalidMessage}
            id={OrderFields.ORDER_COMMENT}
            onChangeHandler={onChange}
            label="Внутренний комментарий:"
            phrases={[
              {
                value: 'Данные уточнены',
                label: 'Данные уточнены',
              },
              ...(currentUser?.role === UserRole.MANAGER
                ? [
                    {
                      value: `Запросили: ${getTextOfDataRequestTemplate(form)}`,
                      label: 'Мы запросили данные',
                    },
                  ]
                : []),
            ]}
          />
        </li>
        {isCreate ? null : (
          <>
            <li className="list-group-item">
              <div className="p-0">
                <SelectControl
                  value={form[OrderFields.RESOLUTION]}
                  isValid={errors[OrderFields.RESOLUTION].isValid}
                  invalidMessage={errors[OrderFields.RESOLUTION].invalidMessage}
                  options={selectLists[OrderFields.RESOLUTION]}
                  id={OrderFields.RESOLUTION}
                  onChangeHandler={onChange}
                  className="col-lg-4 col-md-6 col-sm-12"
                  label="Резолюция:"
                />
              </div>
            </li>
            <li className="list-group-item">
              <TextareaControl
                value={form[OrderFields.RESOLUTION_COMMENT]}
                isValid={errors[OrderFields.RESOLUTION_COMMENT].isValid}
                invalidMessage={errors[OrderFields.RESOLUTION_COMMENT].invalidMessage}
                id={OrderFields.RESOLUTION_COMMENT}
                onChangeHandler={onChange}
                label="Комментарий к резолюции:"
                withCross
                phrases={[
                  ...(currentUser?.role === UserRole.MANAGER
                    ? [
                        {
                          value: `Для проверки заявки необходимы следующие данные ${getTextOfDataRequestTemplate(form)}`,
                          label: 'Запрос данных',
                        },
                        {
                          value: 'На территории РФ нельзя работать с ВУ Узбекистана/Таджикистана/Азербайджана',
                          label: 'Запрет ВУ иностранцев',
                        },
                        {
                          value: 'Пожалуйста, направьте все документы',
                          label: 'Направьте все документы',
                        },
                      ]
                    : []),
                ]}
              />
            </li>
          </>
        )}
      </ul>
    </>
  )
}

export default Manager
