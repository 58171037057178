import { transformResponse, v3Api } from './axios'

export const getBillingSettings = (token: string) =>
  transformResponse(
    v3Api.get('billing', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const getCurrentBillingSettings = (token: string) =>
  transformResponse(
    v3Api.get(`billing/current`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const setBillingSettings = (customer: number, data: any, token: string) =>
  transformResponse(
    v3Api.post(`billing/${customer}`, data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const createInvoice = (data: any, token: string) =>
  transformResponse(
    v3Api.post(`billing/invoice`, data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )
