import { Order, OrderFields, OrderTypeId, ResolutionId } from 'types/order'
import { groupOrders } from './group-orders'

export class BelarusSorter {
  static sort(orders: Order[]) {
    const [belarusOrders, regularOrders] = groupOrders(orders, BelarusSorter.isBelarusOrder)

    return [...regularOrders, ...belarusOrders]
  }

  static isBelarusOrder(order: Order): boolean {
    return (
      String(order[OrderFields.RESOLUTION]) === ResolutionId.E_PROCESSING &&
      !!order[OrderFields.HAS_BELARUSIANS] &&
      [OrderTypeId.EMPLOYEE, OrderTypeId.STATE_DRIVER].includes(String(order[OrderFields.ORDER_TYPE]) as OrderTypeId)
    )
  }
}
