import { UserRole } from 'types/user'
import { Order } from 'types/order'
import { CommonOrdersSorter } from './common-orders-sorter'
import { L1Sorter } from './l1-sorter'
import { ExpertSorter } from './expert-sorter'
import { YandexTaxiGroupSorter } from './yandex-taxi-group-sorter'
import { BelarusSorter } from './belarus-sort'

export class SorterFactory {
  static create(role: UserRole) {
    switch (role) {
      case UserRole.MANAGER:
        return SorterFactory.compose(L1Sorter.sort, YandexTaxiGroupSorter.sort, BelarusSorter.sort)
      case UserRole.EXPERT:
        return SorterFactory.compose(ExpertSorter.sort, YandexTaxiGroupSorter.sort, BelarusSorter.sort)
      default:
        return CommonOrdersSorter.sort
    }
  }

  static compose(...fns: Function[]) {
    return (orders: Order[]) =>
      fns.reduce((sortedOrders: Order[], fn: Function) => {
        return fn(sortedOrders)
      }, orders)
  }
}
