import { createAction } from 'redux-actions'

import { EntityValue } from 'types/core/entity-value'
import { CreateCustomerData, Customer, EditCustomerData, EditCustomerSettingsData } from 'types/customer'
import { CreateUserData, EditUserData, User, UserSettings } from 'types/user'
import { Props } from 'types/core/props'

export const SET_INITIAL_ADMIN_STATE = 'SET_INITIAL_ADMIN_STATE'
export const setInitialAdminState = createAction<void>(SET_INITIAL_ADMIN_STATE)

export const DEACTIVATE_USER_REQUEST = 'DEACTIVATE_USER_REQUEST'
export const deactivateUserRequest = createAction<string>(DEACTIVATE_USER_REQUEST)

export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS'
export const deactivateUserSuccess = createAction<void>(DEACTIVATE_USER_SUCCESS)

export const DEACTIVATE_USER_FAILURE = 'DEACTIVATE_USER_FAILURE'
export const deactivateUserFailure = createAction<void>(DEACTIVATE_USER_FAILURE)

export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST'
export const getCompaniesRequest = createAction<void>(GET_COMPANIES_REQUEST)

export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS'
export const getCompaniesSuccess = createAction<EntityValue[]>(GET_COMPANIES_SUCCESS)

export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE'
export const getCompaniesFailure = createAction<Error>(GET_COMPANIES_FAILURE)

export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST'
export const getCustomersRequest = createAction<void>(GET_CUSTOMERS_REQUEST)

export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS'
export const getCustomersSuccess = createAction<Customer[]>(GET_CUSTOMERS_SUCCESS)

export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE'
export const getCustomersFailure = createAction<Error>(GET_CUSTOMERS_FAILURE)

export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST'
export const editCustomerRequest = createAction<EditCustomerData>(EDIT_CUSTOMER_REQUEST)

export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS'
export const editCustomerSuccess = createAction<void>(EDIT_CUSTOMER_SUCCESS)

export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE'
export const editCustomerFailure = createAction<Error>(EDIT_CUSTOMER_FAILURE)

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST'
export const createCustomerRequest = createAction<CreateCustomerData>(CREATE_CUSTOMER_REQUEST)

export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const createCustomerSuccess = createAction<void>(CREATE_CUSTOMER_SUCCESS)

export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE'
export const createCustomerFailure = createAction<Error>(CREATE_CUSTOMER_FAILURE)

export const EDIT_CUSTOMER_SETTINGS_REQUEST = 'EDIT_CUSTOMER_SETTINGS_REQUEST'
export const editCustomerSettingsRequest = createAction<EditCustomerSettingsData>(EDIT_CUSTOMER_SETTINGS_REQUEST)

export const EDIT_CUSTOMER_SETTINGS_SUCCESS = 'EDIT_CUSTOMER_SETTINGS_SUCCESS'
export const editCustomerSettingsSuccess = createAction<void>(EDIT_CUSTOMER_SETTINGS_SUCCESS)

export const EDIT_CUSTOMER_SETTINGS_FAILURE = 'EDIT_CUSTOMER_SETTINGS_FAILURE'
export const editCustomerSettingsFailure = createAction<Error>(EDIT_CUSTOMER_SETTINGS_FAILURE)

export const FETCH_GET_USERS_REQUEST = 'FETCH_GET_USERS_REQUEST'
export const fetchGetUsersRequest = createAction<void>(FETCH_GET_USERS_REQUEST)

export const FETCH_GET_USERS_SUCCESS = 'FETCH_GET_USERS_SUCCESS'
export const fetchGetUsersSuccess = createAction<User[]>(FETCH_GET_USERS_SUCCESS)

export const FETCH_GET_USERS_FAILURE = 'FETCH_GET_USERS_FAILURE'
export const fetchGetUsersFailure = createAction<Error>(FETCH_GET_USERS_FAILURE)

export const FETCH_EDIT_USER_REQUEST = 'FETCH_EDIT_USER_REQUEST'
export const fetchEditUserRequest = createAction<EditUserData>(FETCH_EDIT_USER_REQUEST)

export const FETCH_EDIT_USER_SUCCESS = 'FETCH_EDIT_USER_SUCCESS'
export const fetchEditUserSuccess = createAction<void>(FETCH_EDIT_USER_SUCCESS)

export const FETCH_EDIT_USER_FAILURE = 'FETCH_EDIT_USER_FAILURE'
export const fetchEditUserFailure = createAction<Error>(FETCH_EDIT_USER_FAILURE)

export const FETCH_CREATE_USER_REQUEST = 'FETCH_CREATE_USER_REQUEST'
export const fetchCreateUserRequest = createAction<CreateUserData>(FETCH_CREATE_USER_REQUEST)

export const FETCH_CREATE_USER_SUCCESS = 'FETCH_CREATE_USERS_SUCCESS'
export const fetchCreateUserSuccess = createAction<void>(FETCH_CREATE_USER_SUCCESS)

export const FETCH_CREATE_USER_FAILURE = 'FETCH_CREATE_USER_FAILURE'
export const fetchCreateUserFailure = createAction<Error>(FETCH_CREATE_USER_FAILURE)

export const UPDATE_USER_SETTINGS_REQUEST = 'UPDATE_USER_SETTINGS_REQUEST'
export const updateUserSettingsRequest = createAction<UserSettings>(UPDATE_USER_SETTINGS_REQUEST)

export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS'
export const updateUserSettingsSuccess = createAction<UserSettings>(UPDATE_USER_SETTINGS_SUCCESS)

export const UPDATE_USER_SETTINGS_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE'
export const updateUserSettingsFailure = createAction<Error>(UPDATE_USER_SETTINGS_FAILURE)

export const CREATE_COMPANY_DETAILS_REQUEST = 'CREATE_COMPANY_DETAILS_REQUEST'
export const createCompanyDetailsRequest = createAction<Props>(CREATE_COMPANY_DETAILS_REQUEST)

export const CREATE_COMPANY_DETAILS_SUCCESS = 'CREATE_COMPANY_DETAILS_SUCCESS'
export const createCompanyDetailsSuccess = createAction<void>(CREATE_COMPANY_DETAILS_SUCCESS)

export const CREATE_COMPANY_DETAILS_FAILURE = 'CREATE_COMPANY_DETAILS_FAILURE'
export const createCompanyDetailsFailure = createAction<Error>(CREATE_COMPANY_DETAILS_FAILURE)

export const UPDATE_COMPANY_DETAILS_REQUEST = 'UPDATE_COMPANY_DETAILS_REQUEST'
export const updateCompanyDetailsRequest = createAction<{ companyId: string; company: Props }>(UPDATE_COMPANY_DETAILS_REQUEST)

export const UPDATE_COMPANY_DETAILS_SUCCESS = 'UPDATE_COMPANY_DETAILS_SUCCESS'
export const updateCompanyDetailsSuccess = createAction<void>(UPDATE_COMPANY_DETAILS_SUCCESS)

export const UPDATE_COMPANY_DETAILS_FAILURE = 'UPDATE_COMPANY_DETAILS_FAILURE'
export const updateCompanyDetailsFailure = createAction<Error>(UPDATE_COMPANY_DETAILS_FAILURE)
