import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { checkVersionRequest } from 'store/version/version.actions'

import StatisticHeader from 'components/organisms/StatisticHeader/StatisticHeader'
import StatisticBody from 'components/organisms/StatisticBody'

export enum StatisticBodyType {
  FEED = 'feed',
  EXPERT = 'expert',
  QUALITY_CONTROL = 'quality-control',
  GREEN = 'green',
}

export const StatisticPage: React.FC = () => {
  const [statisticBodyType, setStatisticBodyType] = useState(StatisticBodyType.FEED)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkVersionRequest())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container page-content d-flex flex-column flex-grow-1">
      <StatisticHeader statisticBodyType={statisticBodyType} setStatisticBodyType={setStatisticBodyType} />

      <StatisticBody type={statisticBodyType} />
    </div>
  )
}

export default StatisticPage
