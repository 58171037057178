import React from 'react'

import { FieldTemplate } from 'types/core/field-template'
import { Props, PropValue } from 'types/core/props'
import { Template } from 'types/core/template'

import InputControl from 'components/molecules/InputControl'
import Button from 'components/molecules/Button'
import { Modal } from 'components/molecules/Modal'

interface CompanyDetailsProps {
  isVisible: boolean
  isLoading: boolean
  template: Template
  companyDetails: Props
  onChange: (value: PropValue, id: string) => void
  onSubmit: () => void
  handleClose: () => void
  detailsIsChanged: boolean
}

const BillingCompanyDetailsForm: React.FC<CompanyDetailsProps> = ({
  isVisible,
  isLoading,
  template,
  companyDetails,
  onChange,
  onSubmit,
  detailsIsChanged,
  handleClose,
}) => {
  const header = 'Реквизиты'

  return (
    <Modal isOpen={isVisible} footer={null} handleClose={handleClose} header={header}>
      <div>
        {template?.fields.map((field: FieldTemplate) => (
          <div className="font-weight-bold" style={{ marginBottom: '0.5rem' }} key={field.name}>
            <InputControl
              id={field.name}
              type="text"
              className="col-12"
              value={companyDetails[field.name] ? `${companyDetails[field.name]}` : ''}
              onChangeHandler={onChange}
              label={field.title}
              placeholder={field.example ? `${field.example}` : ''}
            />
          </div>
        ))}
        <div className="d-flex justify-content-end w-100" style={{ padding: '4px' }}>
          <Button isLoading={isLoading} onClick={onSubmit} content="Сохранить" disabled={!detailsIsChanged} />
        </div>
      </div>
    </Modal>
  )
}

export default BillingCompanyDetailsForm
