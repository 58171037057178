import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

const PlugBilling: React.FC = () => {
  return (
    <div className="container d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="alert alert-warning text-center" role="alert">
        <h4 className="alert-heading">Внимание!</h4>
        <p>Данный раздел Вам недоступен.</p>
        <hr />
        <p className="mb-0">
          Обратитесь за доступом к вашему руководителю или в{' '}
          <a href="https://t.me/ekaterina_valeska" target="_blank" rel="noopener noreferrer">
            поддержку ОТК.
          </a>
        </p>
      </div>
    </div>
  )
}

export default PlugBilling
