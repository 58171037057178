import { put, select, call } from 'redux-saga/effects'

import { AlertVariant } from 'types/alert'

import { selectVersion } from 'store/version/version.selectors'
import { checkVersionSuccess } from 'store/version/version.actions'
import { fetchVersion } from 'requests/version'

import { addAlert } from 'store/alerts/alerts.actions'

export function* checkVersionSaga() {
  try {
    const oldVersion = yield select(selectVersion)
    const newVersion = yield call(fetchVersion)

    if (oldVersion === null) {
      yield put(checkVersionSuccess(newVersion))
    } else if (oldVersion !== newVersion) {
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href
      yield put(
        addAlert({
          text: `Вышло обновление сервиса. Обновите страницу.`,
          variant: AlertVariant.INFO,
          header: 'Обновление',
        }),
      )
    }
  } catch (error) {
    console.error(`[fetchCreateUserSaga]: ${error}`)
  }
}
