import React, { ReactNode } from 'react'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
  error: Error | null
  forceRender: boolean
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false, error: null, forceRender: true }
  }

  static getDerivedStateFromError(error: Error) {
    if (error.name === 'NotFoundError') return { hasError: true, error, forceRender: true }

    return { hasError: true, error, forceRender: false }
  }

  render() {
    const { children } = this.props
    const { hasError, error, forceRender } = this.state

    if (hasError && !forceRender) {
      // @TODO implement using react-bootstrap
      return (
        <div>
          <h1>{error?.name || 'Error'}</h1>
          <p>{error?.message || 'Something went wrong.'}</p>
        </div>
      )
    }

    return children
  }
}
