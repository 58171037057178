import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { LoadingStatus } from 'types/loading'
import { OrderFields, OrderSpaceName } from 'types/order'
import { AutoCheckType } from 'types/auto-checks'
import { Feature } from 'types/user'
import { DictionaryEntryType } from 'types/dictionary'

import { selectOrderRead } from 'store/order/order.selectors'
import { selectAutoChecks, selectLoadingAutoCheck } from 'store/autoChecks/autoChecks.selectors'
import { usePhoneDictionaryLinksByEntry } from 'store/dictionary/dictionary.hooks'

import { getStringWithSpacesFromFields } from 'utils/string'

import PhoneForExpert from 'components/molecules/OrderSpace/components/PhoneForExpert'
import TextWithPrefixAndCopy from 'components/molecules/TextWithPrefixAndCopy'
import ButtonGetDataFromDGOnFioAndBirth from 'components/molecules/ButtonGetDataFromDGOnFioAndBirth'
import { onClickAnchor } from 'components/molecules/OrderSpace/spaces/Header/components/utils/block'
import PhoneVerificationPanel from 'components/molecules/OrderSpace/components/PhoneVerificationPanel'
import { getCheckByType } from 'components/molecules/OrderSpace/spaces/Driver/utils'
import BacklitTextForCheckedValue from 'components/atoms/BacklitTextForCheckedValue'
import LoadingProgressIndeterminate from 'components/atoms/LoadingProgressIndeterminate'
import PhonesFromDictionary from 'components/molecules/OrderSpace/components/PhonesFromDictionary'
import ButtonGetDataFromDGOnTel from 'components/molecules/ButtonGetDataFromDGOnTel'
import FoundArticles from 'components/molecules/OrderSpace/components/FoundArticles'
import PhoneNumberLink from 'components/atoms/PhoneNumberLink'
import CertificateLink from 'components/atoms/CertificateLink'
import { Icon, IconType } from 'components/atoms/Icon'
import NegativeStatus from 'components/molecules/NegativeStatus'
import GrayText from 'components/atoms/GrayText'
import BoldText from 'components/atoms/BoldText'
import Indent from 'components/atoms/Indent'

import classes from '../../style.module.css'

const Driver: React.FC = () => {
  const form = useSelector(selectOrderRead)

  const autoCheckDriver = useSelector(selectAutoChecks)?.filter((check) => check.type === AutoCheckType.DRIVER)

  const checkChaptersDriver = getCheckByType(autoCheckDriver, 'chapters')
  const checkTel1Driver = getCheckByType(autoCheckDriver, 'tel1')
  const checkTel2Driver = getCheckByType(autoCheckDriver, 'tel2')
  const foundPhones = getCheckByType(autoCheckDriver, 'find_phones')
  const negativeDriver = getCheckByType(autoCheckDriver, 'negative')

  const phonesAreFound = useMemo(() => !!foundPhones?.data?.phones?.length, [foundPhones])

  const loadingAutoCheckStatus = useSelector(selectLoadingAutoCheck)

  const [dictionaryLinkOfFirstPhone, dictionaryLinkOfSecondPhone] = usePhoneDictionaryLinksByEntry(
    {
      name: form[OrderFields.DRIVER_FIO],
      birthday: form[OrderFields.DRIVER_BITH],
      type: DictionaryEntryType.PERSON,
    },
    [form[OrderFields.DRIVER_TEL_1], form[OrderFields.DRIVER_TEL_2]],
  )

  return (
    <>
      <div className={classes.space}>
        <div>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <span className={classNames(classes.icon, classes.anchor)} onClick={() => onClickAnchor(`__${OrderSpaceName.DRIVER}`)} role="button">
            <Icon type={IconType.Driver} width="24px" height="24px" />
          </span>
        </div>
        <div className={classes.spaceBody}>
          <div style={{ marginBottom: '4px' }}>
            <div className="d-inline-block">
              <GrayText text="Водитель" />
            </div>
            {form[OrderFields.DRIVER_CREDIT_RATING] ? <span className="text-danger">&nbsp;(Кредитный рейтинг)</span> : null}
          </div>
          <div className="d-flex flex-column" style={{ marginBottom: '8px', gap: '2px' }}>
            <div>
              <TextWithPrefixAndCopy
                text={
                  <div className="d-inline-block">
                    <BoldText text={form[OrderFields.DRIVER_FIO]} />
                  </div>
                }
                prefix="ФИО:"
                copiedText={getStringWithSpacesFromFields(form[OrderFields.DRIVER_FIO], form[OrderFields.DRIVER_BITH])}
              />
              {form[OrderFields.DRIVER_BITH] ? <BoldText text={form[OrderFields.DRIVER_BITH]} /> : null}
            </div>
            <Indent>
              <BacklitTextForCheckedValue field={OrderFields.DRIVER_FIO} />
              <ButtonGetDataFromDGOnFioAndBirth
                fio={form[OrderFields.DRIVER_FIO]}
                date={form[OrderFields.DRIVER_BITH]}
                bases={checkChaptersDriver ? checkChaptersDriver.data : null}
              />
              <CertificateLink type="person" identityData={{ ФИО: form[OrderFields.DRIVER_FIO], ДР: form[OrderFields.DRIVER_BITH] }} />
              <NegativeStatus check={negativeDriver} hideEmpty />
              <FoundArticles check={negativeDriver} />
            </Indent>
          </div>

          <GrayText text="Телефоны в заявке:" />
          <div className="mb-2" style={{ marginTop: '2px' }}>
            <PhoneForExpert
              check={checkTel1Driver}
              field={OrderFields.DRIVER_TEL_1}
              methodOfVerification={dictionaryLinkOfFirstPhone?.data?.linkSource}
            >
              <PhoneVerificationPanel
                person={{ name: form[OrderFields.DRIVER_FIO], birthday: form[OrderFields.DRIVER_BITH] }}
                phone={form[OrderFields.DRIVER_TEL_1]}
              />
            </PhoneForExpert>
          </div>
          <PhoneForExpert
            check={checkTel2Driver}
            field={OrderFields.DRIVER_TEL_2}
            methodOfVerification={dictionaryLinkOfSecondPhone?.data?.linkSource}
          >
            <PhoneVerificationPanel
              person={{ name: form[OrderFields.DRIVER_FIO], birthday: form[OrderFields.DRIVER_BITH] }}
              phone={form[OrderFields.DRIVER_TEL_2]}
            />
          </PhoneForExpert>

          {loadingAutoCheckStatus === LoadingStatus.PENDING ? (
            <div style={{ marginTop: '8px' }}>
              <LoadingProgressIndeterminate />
            </div>
          ) : null}

          <hr className={classes.hr} />
          <GrayText text="Телефоны из справочника:" />

          <div style={{ marginTop: '2px' }}>
            <PhonesFromDictionary
              person={{ name: form[OrderFields.DRIVER_FIO], birthday: form[OrderFields.DRIVER_BITH] }}
              existingPhones={[form[OrderFields.DRIVER_TEL_1], form[OrderFields.DRIVER_TEL_2]]}
            />
          </div>

          {!form[OrderFields.CUSTOMER_FEATURES]?.[Feature.HIDE_FOUND_PHONES] && phonesAreFound ? (
            <>
              <hr className={classes.hr} />
              <GrayText text="Телефоны, которые сами нашли:" />
            </>
          ) : null}
          {!form[OrderFields.CUSTOMER_FEATURES]?.[Feature.HIDE_FOUND_PHONES] && phonesAreFound
            ? foundPhones.data?.phones?.map((phone: string) => (
                <div key={phone} style={{ margin: '2px 0' }}>
                  <div className="d-inline-block d-flex align-items-center">
                    <TextWithPrefixAndCopy text={<PhoneNumberLink phone={`7${phone}`} />} prefix="Тел:" copiedText={`7${phone}`} />
                  </div>
                  <Indent>
                    <ButtonGetDataFromDGOnTel tel={`7${phone}`} check={foundPhones} />
                  </Indent>
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  )
}

export default Driver
