import { createAction } from 'redux-actions'
import { Feature, LoginData, User, UserSettings } from 'types/user'

export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST'
export const fetchCurrentUserRequest = createAction<void>(FETCH_CURRENT_USER_REQUEST)

export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
export const fetchCurrentUserSuccess = createAction<User>(FETCH_CURRENT_USER_SUCCESS)

export const FETCH_CURRENT_USER_PRELOADED = 'FETCH_CURRENT_USER_PRELOADED'
export const fetchCurrentUserPreload = createAction<void>(FETCH_CURRENT_USER_PRELOADED)

export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE'
export const fetchCurrentUserFailure = createAction<Error>(FETCH_CURRENT_USER_FAILURE)

export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST'
export const fetchLoginRequest = createAction<LoginData>(FETCH_LOGIN_REQUEST)

export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS'
export const fetchLoginSuccess = createAction<void>(FETCH_LOGIN_SUCCESS)

export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE'
export const fetchLoginFailure = createAction<Error>(FETCH_LOGIN_FAILURE)

export const FETCH_LOGOUT_REQUEST = 'FETCH_LOGOUT_REQUEST'
export const fetchLogoutRequest = createAction<void>(FETCH_LOGOUT_REQUEST)

export const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS'
export const fetchLogoutSuccess = createAction<void>(FETCH_LOGOUT_SUCCESS)

export const FETCH_LOGOUT_FAILURE = 'FETCH_LOGOUT_FAILURE'
export const fetchLogoutFailure = createAction<Error>(FETCH_LOGOUT_FAILURE)

export const UPDATE_USER_SETTINGS_REQUEST = 'UPDATE_USER_SETTINGS_REQUEST'
export const updateUserSettingsRequest = createAction<UserSettings>(UPDATE_USER_SETTINGS_REQUEST)

export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS'
export const updateUserSettingsSuccess = createAction<UserSettings>(UPDATE_USER_SETTINGS_SUCCESS)

export const UPDATE_USER_SETTINGS_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE'
export const updateUserSettingsFailure = createAction<Error>(UPDATE_USER_SETTINGS_FAILURE)

export const UPDATE_USER_FEATURES = 'UPDATE_USER_FEATURES'
export const updateUserFeatures = createAction<{ [key in Feature]?: any }>(UPDATE_USER_FEATURES)
