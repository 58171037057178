import { call, put, select } from 'redux-saga/effects'
import { fetchOrderList } from 'requests/order'

import { selectToken } from 'store/users/users.selectors'

import { addHttpError } from 'store/http-error/http-error.actions'

import { fetchOrdersSuccess, fetchOrdersFailure } from '../orders.actions'

export function* fetchOrdersSaga() {
  try {
    const token = yield select(selectToken)
    const orders = yield call(fetchOrderList, token)
    yield put(fetchOrdersSuccess(orders.response))
  } catch (error) {
    yield put(fetchOrdersFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение списка заявок',
        defaultText: 'Не удалось получить список заявок',
      }),
    )
    console.error(`[fetchOrdersSaga]: ${error}`)
  }
}
