import { FieldTemplate } from './field-template'

export interface Template {
  name: string
  fields: FieldTemplate[]
  title: string
}

export enum TemplateType {
  CUSTOMER_SETTINGS = 'customer-settings',
  COMPANY_DETAILS = 'company-details',
}
