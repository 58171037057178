import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import InputMask from 'react-input-mask'

import { convertCyrillicToLatin, determineTransportType, getTransportForServer } from 'utils/transport'

import DropdownMenu from 'components/molecules/DropdownMenu'
import CountryFlag from 'components/atoms/CountryFlag'

import { CountryCode, CountryName, CountryPlaceholder } from 'types/transportType'

import classes from './style.module.css'

export interface InputControlProps {
  id: string
  value: string
  isValid?: boolean | undefined
  onChangeHandler(value: string, id?: string): void
  onPasteHandler?(id?: string, value?: string): void
  className?: string
  label?: string | ReactNode
  invalidMessage?: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
}

const countries = [CountryCode.RU, CountryCode.KZ, CountryCode.BY, CountryCode.KG, CountryCode.UA, CountryCode.UZ]

export const InputTruck: React.FC<InputControlProps> = ({
  id,
  value,
  isValid,
  onChangeHandler,
  onPasteHandler = () => {},
  className = '',
  label,
  invalidMessage = '',
  required = false,
  disabled = false,
}) => {
  const [currentCountry, setCurrentCountry] = useState(CountryCode.RU)

  const inputRef: any = useRef(null)

  useEffect(() => {
    const truck = determineTransportType(value)

    if (truck.code) setCurrentCountry(truck.code)
  }, [value])

  const onChangeCountry = useCallback(
    (country: CountryCode) => {
      setCurrentCountry(country)
      onChangeHandler('', id)
      if (inputRef.current) {
        inputRef.current.getInputDOMNode().focus()
      }
    },
    [id, onChangeHandler],
  )

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length < 12) {
        const convertedValue = convertCyrillicToLatin(e.target.value)
        onChangeHandler(getTransportForServer(convertedValue), id)
      }
    },
    [onChangeHandler, id],
  )

  const onPaste = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currValue = e.target.value
      const newValue = currValue.replace(/\s{2,}/g, ' ')
      if (currValue !== newValue) onChangeHandler(newValue, id)

      onPasteHandler(id, newValue)
    },
    [onChangeHandler, id, onPasteHandler],
  )

  const placeholder = CountryPlaceholder[currentCountry]

  // eslint-disable-next-line no-nested-ternary
  const validClass = isValid === false ? 'is-invalid' : isValid === true ? 'is-valid' : ''

  const name = useMemo(() => uuidv4(), [])

  const list = useMemo(
    () =>
      countries.map((country, countryId) => ({
        id: countryId,
        node: (
          <div>
            <span>
              <CountryFlag code={country} />
            </span>
            <span className={classes.countryName}>{CountryName[country]}</span>
          </div>
        ),
        disabled: currentCountry === country,
        onClick: () => onChangeCountry(country),
        data: country,
      })),
    [currentCountry, onChangeCountry],
  )

  return (
    <Form.Group className="m-0">
      {label ? <Form.Label className="d-block">{label}</Form.Label> : null}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
      {!disabled ? (
        <DropdownMenu
          toggleText={
            <div className={classes.icon}>
              <CountryFlag code={currentCountry} />
            </div>
          }
          classNameForToggle={classes.dropdownToggle}
          classNameForMenu={classNames(classes.dropdownMenu, className)}
          classNameForMenuItem={classes.dropdownItem}
          toggleVariant="light"
          list={list}
          onClickItem={(item) => setCurrentCountry(item.data)}
        />
      ) : null}

      <InputMask
        id={id}
        className={classNames('inputMask form-control', validClass, classes.input, className)}
        value={value || ''}
        mask=""
        maskChar={null}
        onChange={onChange}
        onBlur={onPaste}
        required={required}
        autoComplete="none"
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        ref={inputRef}
        alwaysShowMask={!disabled}
      />
      {invalidMessage && isValid === false ? <Form.Control.Feedback type="invalid">{invalidMessage}</Form.Control.Feedback> : null}
    </Form.Group>
  )
}

export default InputTruck
