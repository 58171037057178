import { put, call, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { DGQuery, DGId } from 'types/data-gateway'
import { addHttpError } from 'store/http-error/http-error.actions'
import { selectToken } from 'store/users/users.selectors'

import { fetchGetIdDataGateway } from 'requests/data-gateway'

import { fetchDataGatewaySuccess, fetchDataGatewayFailure } from '../data-gateway.actions'

export function* fetchPersonFromDGSaga(action: Action<DGQuery>) {
  try {
    const token = yield select(selectToken)

    const responseId: DGId = yield call(fetchGetIdDataGateway, action.payload, token)

    if (!responseId || !responseId.id) throw new Error('Нет ответа от сервера data-gateway.')

    window.open(`${process.env.REACT_APP_DATA_GATEWAY}/person/html/${responseId.id}`)
    yield put(fetchDataGatewaySuccess())
  } catch (error) {
    yield put(fetchDataGatewayFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение данных по ФИО',
        defaultText: 'Не удалось получить данные по ФИО.',
      }),
    )
    console.error(`[fetchGetIdDataGateway]: ${error}`)
  }
}
