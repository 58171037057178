import { put, select, call } from 'redux-saga/effects'

import { selectToken } from 'store/users/users.selectors'
import { addHttpError } from 'store/http-error/http-error.actions'

import { User } from 'types/user'

import { fetchUserList } from 'requests/user'

import { fetchGetUsersSuccess, fetchGetUsersFailure } from 'store/admin/admin.actions'

export function* fetchGetUsersSaga() {
  try {
    const token = yield select(selectToken)

    const users: { users: User[] } = yield call(fetchUserList, token)

    yield put(fetchGetUsersSuccess(users.users))
  } catch (error) {
    yield put(fetchGetUsersFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Получение списка пользователей',
        defaultText: 'Не удалось получить список пользователей.',
      }),
    )
    console.error(`[fetchGetUsersSaga]: ${error}`)
  }
}
