import { CountryCode, translationMap } from '../types/transportType'

export const truckRegex =
  'regex:/^(([a-zA-Z]{1}[0-9]{3}[a-zA-Z]{2}[0-9]{2,3})|([0-9]{3}[A-Z]{3}[0-9]{2})|([a-zA-Z]{1}[0-9]{4}[a-zA-Z]{2})|([0-9]{2}KG[0-9]{3,4}[A-Z]{1,3})|([0-9]{5,6}[A-Z]{1,3})|([0-9]{6}[A-Z]{2})|([A-Z]{2}[0-9]{4}[1-9]{1})|([A-Z][0-9]{3}[A-Z]{2,3}))$/'

export const getTransportForServer = (numberTS: string) => `${numberTS}`

export const determineTransportType = (text: string, isFromPaste?: boolean) => {
  const transport = getTransportForServer(text)

  const ru = transport.match(/^[a-zA-Z]{1}\d{3}[a-zA-Z]{2}\d{2,3}$/)
  if (ru) return { value: transport, code: CountryCode.RU }

  const kz = transport.match(/^((\d{3}[A-Z]{3}\d{2})|([A-Z]\d{3}[A-Z]{2,3}))$/)
  if (kz) return { value: transport, code: CountryCode.KZ }

  const by = transport.match(/^[a-zA-Z]\d{4}[a-zA-Z]{2}$/)
  if (by) return { value: transport, code: CountryCode.BY }

  const kg = transport.match(/^((\d{2}KG\d{3,4}[A-Z]{1,3})|(\d{5,6}[A-Z]{1,3}))$/)
  if (kg) return { value: transport, code: CountryCode.KG }

  const ua = transport.match(/^[A-Z]{2}\d{4}\d$/)
  if (ua) return { value: transport, code: CountryCode.UA }

  const uz = transport.match(/^\d{6}[A-Z]{2}$/)
  if (uz) return { value: transport, code: CountryCode.UZ }

  return { value: text?.length > 6 || isFromPaste ? text : '', code: null }
}

export const convertCyrillicToLatin = (text: string) => {
  return text
    .toUpperCase()
    .split('')
    .map((char: string) => translationMap[char] || char)
    .join('')
    .replace(/[-._/\\()\s"';:]/g, '')
}
