import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { SelectItem } from 'types/selectLists'

import { createReport } from 'requests/order'

import { selectToken } from 'store/users/users.selectors'

import Button from 'components/molecules/Button'
import SelectControl from 'components/molecules/SelectControl'
import { nameOfMonths } from 'constants/date'

const MIN_YEAR = 2020

const MONTHS_MAP: { [key: number]: string } = nameOfMonths.reduce((obj, item, index) => ({ ...obj, [index]: item }), {})

export const ReportForm: React.FC = () => {
  const token = useSelector(selectToken)

  const [value, setValue] = useState<string>(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`)
  const [isLoading, setIsLoading] = useState(false)

  const handleValueChange = useCallback((option: string) => {
    setValue(option)
  }, [])

  const generateReport = useCallback(() => {
    const [year, month] = value.split('-')
    setIsLoading(true)
    createReport({ year, month }, token as string)
      .then((data: { link: string }) => {
        window.location.href = data.link
      })
      .finally(() => setIsLoading(false))
  }, [value, token])

  const options = useMemo(() => {
    const items: SelectItem[] = []
    const now = new Date()
    const currentYear = now.getFullYear()
    for (let year = MIN_YEAR; year <= currentYear; year += 1) {
      const MAX_MONTH = year === currentYear ? now.getMonth() + 1 : 12
      for (let month = 0; month < MAX_MONTH; month += 1) items.push({ id: `${year}-${month + 1}`, name: `${year} - ${MONTHS_MAP[month]}` })
    }
    return items.reverse()
  }, [])

  return (
    <div className="mb-4 mt-3">
      <div className="mb-2 font-weight-bold">
        <SelectControl
          label="Реестр заявок"
          options={options}
          className="col-lg-3 col-md-6 col-sm-12"
          value={value}
          onChangeHandler={handleValueChange}
        />
      </div>
      <div>
        <Button onClick={generateReport} isLoading={isLoading}>
          Получить реестр
        </Button>
      </div>
    </div>
  )
}

export default ReportForm
