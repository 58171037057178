import { call, put, select } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import { searchOrders } from 'requests/order'

import { selectToken } from 'store/users/users.selectors'

import { addHttpError } from 'store/http-error/http-error.actions'

import { fetchOrdersSuccess, fetchOrdersFailure } from '../orders.actions'

export function* searchOrdersSaga(action: Action<string>) {
  try {
    const token = yield select(selectToken)
    const orders = yield call(searchOrders, token, action.payload)
    yield put(fetchOrdersSuccess(orders.response))
  } catch (error) {
    yield put(fetchOrdersFailure(error))
    yield put(
      addHttpError({
        error,
        header: 'Поиск заявок',
        defaultText: 'Не удалось выполнить поиск заявок',
      }),
    )
    console.error(`[searchOrdersSaga]: ${error}`)
  }
}
