import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Feature } from 'types/user'

import { billingPath } from 'routes/url-constants'

import { selectCurrentUser } from 'store/users/users.selectors'

import classes from './style.module.css'

export const GlobalPaymentStateAlert: React.FC = () => {
  const currentUser = useSelector(selectCurrentUser)

  const paymentState = currentUser?.features?.[Feature.PAYMENT_STATE]

  const message = useMemo(() => {
    if (paymentState === 'needPayment') return 'Направлен счет за месяц.'
    if (paymentState === 'owed') return 'У вас долг по оплате. Срочно оплатите во избежание блокировки!'
    if (paymentState === 'blocked') return 'Вы заблокированы за неуплату.'
    return ''
  }, [paymentState])

  if (!message) return null

  return (
    <>
      <div
        className={classNames('text-center', classes.container, paymentState === 'blocked' ? 'bg-danger text-light' : 'bg-warning text')}
        style={{ backgroundColor: '#ffbe3d' }}
      >
        {message}
        {currentUser?.features?.subrole === 'customer-admin' ? (
          <>
            {' '}
            см{' '}
            <Link to={billingPath} className={paymentState === 'blocked' ? 'text-light' : ''} style={{ textDecoration: 'underline' }} role="link">
              счета и оплаты
            </Link>
          </>
        ) : null}
      </div>
    </>
  )
}

export default GlobalPaymentStateAlert
