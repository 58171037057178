import { Props } from 'types/core/props'
import { EntityValue } from 'types/core/entity-value'

import { v3Api, transformResponse } from './axios'

export const getCompanies = (token: string): Promise<EntityValue[]> =>
  transformResponse(
    v3Api.get('/billing/company-details', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const getCompany = (id: string, token: string) =>
  transformResponse(
    v3Api.get(`/billing/company-details/${id}`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const createCompanyDetails = (data: Props, token: string) =>
  transformResponse(
    v3Api.post('/billing/company-details', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const patchCompanyDetails = (id: string, data: Props, token: string) =>
  transformResponse(
    v3Api.patch(`/billing/company-details/${id}`, data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const getCompanyAccount = (id: string, token: string): Promise<EntityValue[]> =>
  transformResponse(
    v3Api.get(`/billing/company-account/${id}`, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const getCurrentCompanyDetails = (token: string) =>
  transformResponse(
    v3Api.get('/billing/company-details/current', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const updateCurrentCompanyDetails = (data: Props, token: string) =>
  transformResponse(
    v3Api.patch('/billing/company-details/current', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )

export const createCurrentCompanyDetails = (data: Props, token: string) =>
  transformResponse(
    v3Api.post('/billing/current-company-details', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }),
  )
