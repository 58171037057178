import axios from 'axios'

import { transformResponse } from './axios'

export const getNews = async () => {
  try {
    return await transformResponse(axios.get(`/news.json?t=${Date.now()}`, { headers: { 'Accept-Type': 'application/json' } }))
  } catch (e) {
    return {}
  }
}
